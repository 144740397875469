/* initialState in Store is reading this setting
*/
const settings = {
  title: 'title',
  queue: null,
  displayQueueCategory: '',
  calcWaitCountType: 'CATEGORY',
  wsurl: undefined,
  droparea: {visible: false, disabled:true, src:'/img/sendfile_drop.png', descriptions: 'fileDrop', fileimg:'/img/sendfile_message_icon_pc.png'},
  announcement: { title:"", msg:""},
  botError: {
    device: 'pc',
    isDisplay: false,
    messages: 'botError.message',
    options: [
      { 
        btntype: 'openLink',
        disabled:false,
        titles: 'botError.options.mail.titles', 
        link: {
          ja: {
            pc: 'https://travel.faq.rakuten.net/form/ask',
            sp: 'https://travel.faq.rakuten.net/form/ask',
          },
          en: {
            pc: 'https://travel-ensupport.faq.rakuten.net/form/ask',
            sp: 'https://travel-ensupport.faq.rakuten.net/form/ask',
          },
          zh_cn: {
            pc: 'https://travel-zh-cnsupport.faq.rakuten.net/form/ask',
            sp: 'https://travel-zh-cnsupport.faq.rakuten.net/form/ask',
          },
          zh_tw: {
            pc: 'https://travel-zh-twsupport.faq.rakuten.net/form/ask',
            sp: 'https://travel-zh-twsupport.faq.rakuten.net/form/ask',
          }
        },
      },
    ]
  },
  botMaintenance: { isMaintenance: false },
  chatSetting: {},
  connection: {},
  disconnect: {isDisplay: false, disabled: true},
  icon: {
    logoSrc: {
      ja: '/img/travel_icon.png',
      en: '/img/travel_icon_inbound.png',
      zh_cn: '/img/travel_icon_inbound.png',
      zh_tw: '/img/travel_icon_inbound.png'
    },
    logoClassName: '',
    radius: '50%',
    bg: '#fff'
  },
  input: {
    value: '',
    placeholder: 'placeholder.bot',
    disabled: false,
  },
  laAvailability:{},
  menu: {
    device: 'pc',
    isActive: "",
    isDisplay: true,
    disabled: false,
    menus: [
      {
        id: 'operator',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.operator.name',
        submit: 'menus.operator.submit',
        link: undefined
      },
      {
        id: 'rgroup',
        disable: false,
        isSkipSubmit: true,
        isDisplay: false,
        name: 'menus.rgroup.name',
        submit: undefined,
        link: {
          pc: 'https://www.rakuten.co.jp/sitemap/inquiry.html',
          sp: 'https://www.rakuten.co.jp/sitemap/sp/inquiry.html',
        }
      },
      {
        id: 'reset',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.reset.name',
        submit: 'menus.reset.submit',
        link: undefined
      }
    ]
  },
  message: {
    device: '',
    botIcon: '/img/travel_bot_icon.png',
    operatorIcon: '/img/operator_message_icon.png',
    radius: '50%',
    bg: '',
    contents: []
  },
  modalWindow: {
    isOpen: false,
    icon: {
      logoSrc: {
        ja: '/img/travel_bot_icon.png',
        en: '',
        zh_cn: '',
        zh_tw: '',
      },
    },
    displayQueueMap: {
      'jp_domes_hotel_default': 'JP_DOMES_HOTEL',
      'jp_ana_package_default': 'JP_ANA_PACKAGE',
      'jp_bus_default': 'JP_BUS',
      'jp_rental_car_default': 'JP_RENTAL_CAR',
    },
  },
  name: { name:'profileName.bot' },
  notification: {
    ignore: false,
    disableActiveWindow: false,
    askAgain: false,
    timeout: 5000,
    title: "",
    options: {
      body: '',
      icon:  '/img/operator_message_icon.png',
      lang:  '',
      image: "a",
      data: {}
    }
  },
  opponent: {},
  order: { orderNumber:'' },
  personel: { personel:'' },
  scroll: {isDisplay:''},
  shop: { shopId:'' },
  socket: "",
  socketAction: {
    upload: true
  },
  statusLamp: {
    status: "online",
    isDisplayStatus: true,
  },
  status : {
    text:"",
    classification: ""
  },
  submit: {opponent: 'bot', disabled: true, grayOut: false, screenSize: ''},
  suggestion:  {
    device: 'pc',
    index: 0,
    posX: "16px",
    items: [],
    inlineStyle: {
      transform: "translateX(0)",
    }
  },
  typing: {name:'',isDisplay:false},
  upload :  {
    isDisplay: false,
    icon: '/img/travel_sendimage_icon_sp.png',
    fileimg:'/img/travel_sendfile_message_icon_pc.png',
    accept: 'image/*',
    disabled: false,
  },
  userInfo: {
    categorization: {
      'jp_domes_hotel_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
      'jp_ana_package_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
      'jp_outbound_hotel_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
      'jp_outbound_package_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
      'jp_bus_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
      'jp_rental_car_default': [
        { input: 'email', required: true },
        { input: 'kanaName', required: true },
        { input: 'reservationNumber', required: false },
        { input: 'inquiryContent', required: true }
      ],
    },
    inputs: {
      'email': { type: 'email', id: 'modalEmail', maxLength: 100, style: { sp: { width: '90%', height: '2.3em' }, pc: { width: '90%', height: '2.3em' } } },
      'kanaName': { type: 'text', id: 'modalKanaName', maxLength: 190, style: { sp: { width: '90%' }, pc: { width: '90%' } } },
      'reservationNumber': { type: 'text', id: 'modalReservationNumber', maxLength: 100, style: { sp: { width: '90%' }, pc: { width: '90%' }} },
      'inquiryContent': { type: 'text', id: 'modalInquiryContent', maxLength: 300, isTextArea: true, style: { sp: { height: '6em', width: '90%' }, pc: { height: '6em', width: '90%' } } },
    },
    message: {
      'jp_domes_hotel_default': 'defaultMessage',
      'jp_ana_package_default': 'defaultMessage',
      'jp_outbound_hotel_default': 'defaultMessage',
      'jp_outbound_package_default': 'defaultMessage',
      'jp_bus_default': 'defaultMessage',
      'jp_rental_car_default': 'defaultMessage'
    }
  },
  salesforce: {
    id: ''
  }
}

module.exports = {
  settings
}
import React from "react"
import RadiusBtn from "./RadiusBtn"

export default ({buttons, clickButton, disabled}) => (
  <div className="twoChoiceQuestion">
    <ul className="twoChoiceQuestion__items">
      {buttons.map((button, index) => (
      <li className="twoChoiceQuestion__item" key={index}>
          <RadiusBtn text={button.title} value={button.value} type={button.type} clickButton={clickButton} disabled={disabled}/>
      </li>
      ))}
    </ul>
  </div>
)
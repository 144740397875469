import React from "react"
import i18n from "../i18n"
import CustomReactMarkdown from "./CustomReactMarkdown"

export default ({ name }) => {
  const translatedName = (name && i18n.exists(name)) ? i18n.t(name) : name
  return (
    <div className="profile__grid profile__grid--04">
      <span className="profile__names">
        {(translatedName instanceof Array)
          ? translatedName.map((n,index)=>(<p key={index}>{n}</p>))
          : <CustomReactMarkdown className="profile__name" children={translatedName} /> }
      </span>
    </div>
)}

import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("bot-error:initialize"),
  show: Symbol("bot-error:show"),
  hide: Symbol("bot-error:hide"),
}

export default {
  initialize(device){
    Dispatcher.dispatch({
      type: keys.initialize,
      device
    })
  },
  show(){
    Dispatcher.dispatch({
      type: keys.show,
      isDisplay: true
    })
  },
  close(){
    Dispatcher.dispatch({
      type: keys.hide,
      isDisplay: false
    })
  },
}
import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/UserInfoActions"
import { settings } from "../config/settings"

class UserInfoStore extends ReduceStore {

  getInitialState() {
    return settings.userInfo
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return  {...state, ...action.item}
      default:
        return state
    }
  }
}

export default new UserInfoStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/TypingActions"
import { settings } from "../config/settings"

class TypingStore extends ReduceStore {

  getInitialState() {
    return settings.typing
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.setName:
        return {
          ...state,
          name: action.name
        }
      case keys.agentTyping:
      case keys.agentNotTyping:
        return {
          ...state,
          isDisplay: action.isDisplay
        }
      default:
        return state
    }
  }
}

export default new TypingStore(Dispatcher)

import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("suggestion:initialize"),
  show: Symbol("suggestions:show"),
  clear: Symbol("suggestions:clear"),
  next: Symbol("suggestions:next"),
  prev: Symbol("suggestions:prev"),
  setDefaultItem: Symbol("suggestions:set-default-item"),
  showDefaultItem: Symbol("suggestions:show-default-item"),
}

export default {
  initialize(isSP){
    Dispatcher.dispatch({
      type:keys.initialize,
      device: (isSP)?'sp':'pc'
    })
  },
  show(items){
    Dispatcher.dispatch({
      type:keys.show,
      items,
    })
  },
  clear(){
    Dispatcher.dispatch({
      type:keys.clear,
    })
  },
  next() {
    Dispatcher.dispatch({
      type: keys.next,
    })
  },
  prev(state) {
    Dispatcher.dispatch({
      type: keys.prev,
    })
  },
  setDefaultItem(defaultItems){
    Dispatcher.dispatch({
      type:keys.setDefaultItem,
      defaultItems
    })
  },
  showDefaultItem(){
    Dispatcher.dispatch({
      type:keys.showDefaultItem,
    })
  },
}

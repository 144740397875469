import React, { Component } from "react";
import { func, string } from 'prop-types';

class ScrollBtn extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.props.scroll)
  }

  render() {
    return (
      <div className={`toBottomBtn ${this.props.isDisplay}`} onClick={this.props.onClick}></div>
    )
  }
}

ScrollBtn.propTypes = {
  scroll: func,
  isDisplay: string,
  onClick: func,
};

ScrollBtn.defaultProps = {
  isDisplay: '',
};

export default ScrollBtn

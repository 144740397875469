import React from "react"

export default ({ items, device, clickSuggestion, inlineStyle, onCarouselPrev, onCarouselNext }) => (
  (items.length > 0)
    ? (
      <div className="suggestion">
        <div className={`carousel__navWrap carousel__navWrap--prev carousel__navWrap_${device}`}>
          <button className="carousel__nav carousel__nav--prev" onClick={onCarouselPrev}>PREV</button>
        </div>
        <div className={`carousel__navWrap carousel__navWrap--next carousel__navWrap_${device}`}>
            <button className="carousel__nav carousel__nav--next" onClick={onCarouselNext}>NEXT</button>
        </div>
        <div className="carousel">
          <ul className="carousel__items" style={inlineStyle}>
            {items.map((item, index) => (
              <li className="carousel__item" key={index} id={`suggestion_item_${index}`}>
                <button className="carousel__btn" onClick={clickSuggestion} value={item.term}>{item.term}</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
    : (<div></div>)
)
import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/NotificationActions"
import { settings } from "../config/settings"

class NotificationStore extends ReduceStore {

  getInitialState() {
    return settings.notification
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.setIcon:
        state.options.icon = action.icon
        return {type:action.type, ...state}
      case keys.setLang:
        state.options.lang = action.lang
        return {type:action.type, ...state}
      case keys.show:
        state.title = action.title
        state.options.body = action.body
        state.options.data = action.data
        state.options.tag = action.tag
        return {type:action.type, ...state};

      default:
        return state
    }
  }
}

export default new NotificationStore(Dispatcher)

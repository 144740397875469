import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ChatSettingActions"
import { settings } from "../config/settings"
class ChatSettingStore extends ReduceStore {

  getInitialState() {
    return settings.chatSetting
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.setSessionId:
        return {
          ...state,
          chatSessionId: action.chatSessionId
        }
      default:
        return state
    }
  }
}

export default new ChatSettingStore(Dispatcher)

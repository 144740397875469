import Dispatcher from "../Dispatcher"

export const keys = {
  unload: Symbol("order:unload"),
  loading: Symbol("order:loading"),
}

export default {
  load(orderNumber='') {
    Dispatcher.dispatch({
      type: keys.loading,
      orderNumber,
    })
  },
}

import React from "react"
import CustomReactMarkdown from "./CustomReactMarkdown"

export default ({text, clickButton, value}) => {
  const onClick = () => {
    clickButton(value)
  }
  return (
  <div className="squareBtn squareBtn--bordered u-mb8">
    <button className="squareBtn__text" onClick={onClick}>
      <CustomReactMarkdown children={text} />
    </button>
  </div>
)}

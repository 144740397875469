import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ShopActions"
import { settings } from "../config/settings"

class ShopStore extends ReduceStore {
  getInitialState() {
    return settings.shop
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.unload:
      case keys.failed:
        return {...state}
      case keys.succeed:
        return {
          ...state,
          master: action.master
        }
      case keys.loading:
        return {
          ...state,
          shopId: action.shopId
        }
      default:
        return state
    }
  }
}

export default new ShopStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/OrderActions"
import { settings } from "../config/settings"

class OrderStore extends ReduceStore {
  getInitialState() {
    return settings.order
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.unload:
        return {...state}
      case keys.loading:
        return {
          ...state,
          orderNumber: action.orderNumber
        }
      default:
        return state
    }
  }
}

export default new OrderStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ConversationActions"

class ConversationStore extends ReduceStore {

  getInitialState() {
    return { type: keys.stop }
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.start:
      case keys.stop:
        return action
      default:
        return state
    }
  }
}

export default new ConversationStore(Dispatcher)

import React from "react"
import RadiusBtn from "./RadiusBtn"

export default ({buttons, clickButton, disabled, availability={}}) => {

  return (
  <div className="multipleChoiceQuestion">
    <ul className="multipleChoiceQuestion__items">
      {buttons.map((button, index) => {
        return (
          <li className="multipleChoiceQuestion__item" key={index}>
            <RadiusBtn text={button.title} value={button.value} clickButton={clickButton} disabled={!availability[button.category] || disabled} type={button.type}/>
          </li>
        )}
      )}
    </ul>
  </div>
)}

import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("questionnaire:set"),
}

export default {
  set(item){
    Dispatcher.dispatch({
      type: keys.set,
      item,
    })
  },
}
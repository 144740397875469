import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/DisplayQueueCategoryActions";
import { settings } from "../config/settings"

class DisplayQueueCategoryStore extends ReduceStore {

  getInitialState() {
    return settings.displayQueueCategory
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set: {
        const { formKey } = action;
        return settings.modalWindow.displayQueueMap[formKey] || '';
      }
      default:
        return state
    }
  }
}

export default new DisplayQueueCategoryStore(Dispatcher)
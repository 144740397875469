import React from "react"
import MenuItem from './MenuItem'

export default ({isActive, isDisplay, disabled, device, menus, shop, queue, order, botMaintenance, onSubmit, openUrl}) => (
  (isDisplay)?
  (<div className={`hamburgerMenu__items ${isActive}`}>
    {menus.filter((menu,index) => menu.isDisplay).map((menu,index) =>
      <MenuItem {...menu} disabled={disabled} device={device} shop={shop} order={order} botMaintenance={botMaintenance} queue={queue} onSubmit={onSubmit} openUrl={openUrl} key={index}/>
      )}
  </div>)
  : (<div></div>)
)

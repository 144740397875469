import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("personel:set"),
}

export default {
  set(personel){
    Dispatcher.dispatch({
      type: keys.set,
      personel,
    })
  },
}
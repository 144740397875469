import React from "react"
import i18n from "../i18n"

export default ({ logoSrc, logoClassName, radius, bg }) => {
  const { language: lang } = i18n
  const logo = (logoSrc instanceof Object) ? logoSrc[lang] : logoSrc
  return (
  <div className="profile__grid profile__grid--01">
    <div className="profile__icon profile__icon--bot" style={{ borderRadius: radius, background: bg }}>
      <img src={logo} alt={''} className={logoClassName} style={{width: "100%"}}/>
    </div>
  </div>
)}

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ScrollActions"
import { settings } from "../config/settings"

class ScrollStore extends ReduceStore {

  getInitialState() {
    return settings.scroll
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.scroll:
        return {
          ...state,
          isDisplay: action.isDisplay
        }

      default:
        return state
    }
  }
}

export default new ScrollStore(Dispatcher)

import React from "react"
import moment from "moment"
import i18n from "../i18n"

export default ({ text, now, fileimg, isSending, isFile=false, balloonId }) => {
  let lines = text.split("\n")
  let time = moment(now).format("HH:mm")
  const wordNormalClass = (i18n.language === 'en') ? 'messageWordNormal' : ''
  return (
    <div className={`userMessage u-clearfix ${balloonId}`}>
      <div className="userMessage__inner">
        <div className="userMessage__grid userMessage__grid--time">
          <div className="userMessage__time">{time}</div>
        </div>
        <div className="userMessage__grid">
          <div className={`userMessage__balloon ${wordNormalClass}`}>
            {// Merchant and Travel-cs have different structures
              (process.env.REACT_APP_SERVICE==='merchant')
              ?<div className="userMessage__uploaded">
              {(isFile)
                ?<img src={fileimg} alt="" />:<img src={fileimg} alt="" style={{width:'48px'}}/>}
              {(isSending)
                ? <div  className="userMessage__nowUploading">
                    <img className="loadingImg" src="/img/circlepic.png" alt="" />
                  </div>
                : ''}
              </div>
              ://if not merchant
              (fileimg)
              ?<div className="userMessage__uploaded">
              <img src={fileimg} alt="" style={(isFile)?{width:'48px'}:undefined }/>
              {(isSending)
              ? <div  className="userMessage__nowUploading">
                  <img className="loadingImg" src="/img/circlepic.png" alt="" />
                </div>
              : ''}
              </div>
              : ''
            }
            {lines.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

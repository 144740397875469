import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("name:set"),
}

export default {
  set(name){
    Dispatcher.dispatch({
      type: keys.set,
      name,
    })
  },
}
import Dispatcher from "../Dispatcher"

export const keys = {
  setSessionId: Symbol("chat-setting:set-session-id"),
}

export default {
  setSessionId(chatSessionId){
    Dispatcher.dispatch({
      type: keys.setSessionId,
      chatSessionId,
    })
  },
}
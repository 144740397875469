import React from "react"
import i18n from "../i18n"
import { settings } from "../config/settings";
import { calcWaitCount } from '../utils';

export default ({ classification, text, queue, data='', displayQueueCategory, sid }) => {
  const waitCount = calcWaitCount(queue, displayQueueCategory, sid)
  const maxQueueCount = process.env.REACT_APP_MAX_QUEUE_COUNT
  const replacedData = {
    data,
    queue: (queue && maxQueueCount && waitCount >= maxQueueCount) ? maxQueueCount : waitCount,
    more: queue && waitCount >= maxQueueCount ? '以上' : ''
  };
  const translatedText = (text) ? i18n.t(text, replacedData) : []
  const checkedText = (settings.calcWaitCountType === 'CATEGORY' && !displayQueueCategory) ? translatedText.slice(0,1) : translatedText
  return (
  <div className={`notificationBar ${classification}`}>
    <div className={`notificationBar__inner ${classification}`}>
      <span className={`notificationBar__text ${classification}`}>{checkedText.map((text,index) => <p key={index}>{text}</p>)}</span>
      <button className={`notificationBar__btn ${classification}`}>{i18n.t('statusMessage.reconnect')}</button>
    </div>
  </div>
)}

import Dispatcher from "../Dispatcher"

export const keys = {
  change: Symbol("title:change"),
}

export default {
  change(title){
    document.title = title
    Dispatcher.dispatch({
      type: keys.change,
      title,
    })
  },
  add(title){
    document.title = document.title+title
  }
}
import React from "react"
import MultipleChoiceQuestion from "./MultipleChoiceQuestion"
import i18n from "../i18n"

export default ({icon, time, buttons, disabled, clickButton, clickLink }) => {
  const wordNormalClass = (i18n.language === 'en') ? 'messageWordNormal' : ''
  return (
    <div className="operatorMessage u-clearfix">
      <div className="operatorMessage__inner">
        <div className="operatorMessage__grid">
          <div className="operatorMessage__icon">
            <img src={icon} alt={""} style={{width:"100%"}}/>
          </div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--balloon">
          <div className={`operatorMessage__balloon ${wordNormalClass}`}>
            <MultipleChoiceQuestion buttons={buttons} clickButton={clickButton} clickLink={clickLink} disabled={disabled} />
          </div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--time">
          <div className="operatorMessage__time">{time}</div>
        </div>
      </div>
    </div>
)}

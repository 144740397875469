import React from "react"
import { debounce } from 'lodash'
import i18n from "../i18n"

class inputField extends React.Component {
  constructor(props){
    super(props)
    this.typingStatus = false
  }

  _update = debounce(() => {
    this.forceUpdate()
  },500)
  onKeyPress = (event) => {
    this.props.onKeyPress(event)
    if(!this.typingStatus){
      this.typingStatus = true
      this.props.onTyping()
    }
    this._update()
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if((prevProps.value === this.props.value || this.props.value.length === 0) && this.typingStatus){
      this.typingStatus=false
      return this.props.onNotTyping()
    }
    return
  }
  render(){
    return (
        <div className="inputBar__grid">
          <textarea autoFocus id="inputField" className="inputBar__field" 
            onKeyPress={this.onKeyPress} 
            onChange={this.props.onChange}
            placeholder={i18n.t(this.props.placeholder)} value={this.props.value} disabled={this.props.disabled}></textarea>
        </div>
        )
  }
}

export default inputField;
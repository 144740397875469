import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("menu:initialize"),
  open: Symbol("menu:open"),
  close: Symbol("menu:close"),
  toggle: Symbol("menu:toggle"),
  show: Symbol("menu:show"),
  hide: Symbol("menu:hide"),
  disable: Symbol("menu:disable"),
  enable: Symbol("menu:enable"),
}

export default {
  initialize(ids,isSP){
    Dispatcher.dispatch({
      type: keys.initialize,
      ids,
      device: (isSP)?'sp':'pc'
    })
  },
  open(){
    Dispatcher.dispatch({
      type: keys.open,
      isActive: 'is-active'
    }) 
  },
  close(){
    Dispatcher.dispatch({
      type: keys.close,
      isActive: ''
    }) 
  },
  toggle(){
    Dispatcher.dispatch({
      type: keys.toggle,
    }) 
  },
  show(){
    Dispatcher.dispatch({
      type: keys.show,
      isDisplay: true,
    }) 
  },
  hide(){
    Dispatcher.dispatch({
      type: keys.hide,
      isDisplay: false,
    }) 
  },
  disable(){
    Dispatcher.dispatch({
      type: keys.disable,
      disabled: true,
    }) 
  },
  enable(){
    Dispatcher.dispatch({
      type: keys.enable,
      disabled: false,
    }) 
  },
}

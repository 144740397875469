const resources = {
  ja: {
    translation: {
      title: '楽天トラベルカスタマーサポート',
      announcementTitle: 'お知らせ',
      botError: {
        message: [
          '大変申し訳ございません。',
          '現在、自動応答サービスをご利用頂けません。',
          '以下の方法でお問い合わせください。'
        ],
        options: {
          mail: {
            titles: ['メールでお問い合わせ']
          }
        }
      },
      cancelButton: '戻る',
      cancelOperatorMessage: 'オペレーターとのチャットをやめる',
      disconnectButton: {
        main: '切断', span: 'する'
      },
      fileDrop: ['こちらのエリアに','ファイルをドロップしてください。'],
      menus: {
        operator: {
          name: ['オペレーターとチャット'],
          submit: 'オペレーターとチャット'
        },
        rgroup: {
          name: ['楽天グループサービスへの','問い合わせはこちら']
        },
        reset: {
          name: ['最初から質問しなおす'],
          submit: '最初から質問しなおす'
        }
      },
      modal: {
        errorMessage: {
          required: {
            modalEmail: 'この項目は入力必須です。',
            modalKanaName: 'この項目は入力必須です。',
            modalInquiryContent: 'この項目は入力必須です。',
          },
          format: {
            modalEmail: 'フォーマットが不正です。',
            modalKanaName: 'スペースを入れずにひらがなでご入力ください。',
          }
        },
        form: {
          email: {
            name: 'メールアドレス(楽天会員の方は会員情報に登録のメールアドレス)',
          },
          kanaName: {
            name: 'お問い合わせいただいている方のお名前(ひらがな)',
            placeholder: '例)らくてんたろう',
          },
          reservationNumber: { 
            name: '予約番号(予約をお持ちでない場合は「なし」とご入力ください)',
            placeholder: '複数入力可',
           },
          inquiryContent: { name: 'お問い合わせ内容' }
        },
        message: {
          defaultMessage: [
            'スムーズに対応させていただくため、以下のフォームにご入力ください。',
            '</br>[個人情報保護方針](https://privacy.rakuten.co.jp/)',
          ]
        },
        waitcount: '現在$COUNT名$MOREお待ちいただいています。',
      },
      seeMore: 'もっと見る',
      sendButton: '送信',
      statusMessage: {
        connect: ['接続'],
        reconnect: ['再接続'],
        connectingOperator: ['対応可能なオペレーターを検索中です','現在：{{queue}}名{{more}}お待ちです。'],
        operator: ['オペレーターがチャットを開始しました'],
        disconnectOperator: ['オペレーターがチャットを終了しました'],
        disconnectUser: ['チャットの終了を受け付けました'],
        timeoutOperator: ['チャットのセッションが切れました'],
        disconnect: ['切断'],
        connectError: ['接続エラー'],
        reconnectError: ['再接続エラー'],
        reconnectFailed: ['再接続失敗'],
        tryReconnect: ['再接続試行'],
        reconnecting: ['再接続中({{data}}回目)']
      },
      typingMessage: 'がタイピング中',
      placeholder: {
        bot: 'お問い合わせ内容を入力してください。\n例）予約を確認したい、キャンセル方法',
        inputDisable: '表示されているボタンの中から選択してください。',
        selectCategory: 'カテゴリをボタンから選択してください。',
        inputEmail: 'こちらにメールアドレスを入力してください。',
        connectingOperator: 'オペレーターにお繋ぎします。しばらくお待ちください。',
        operator: 'メッセージを入力してください。',
        disconnect: 'ご利用ありがとうございました。\nチャットを行いたい場合は新たにチャットウィンドウを開いてください。',
        operatorError: 'お問い合わせ内容を入力してください。例)住所を変更したい'
      },
      profileName: {
        bot: ['楽天トラベル','カスタマーサポート'],
        connectingOperator: ['検索中'],
        operator: '担当',
        disconnect: ['接続を切断しました。']
      }
    }
  },
  en: {
    translation: {
      title: 'Rakuten Travel Customer Support',
      announcementTitle: 'Notice',
      botError: {
        message: [
          'Apologies.',
          'The automatic response service is not available at the moment.',
          'Please send us your inquiry through the method below.'
        ],
        options: {
          mail: {
            titles: ['Inquire by email']
          }
        }
      },
      cancelButton: '',
      cancelOperatorMessage: '',
      disconnectButton: {
        main: 'Disconnect', span: ''
      },
      fileDrop: ['Please drop the file in this area.'],
      menus: {
        operator: {
          name: ['Connect to live chat operator'],
          submit: 'Connect to live chat operator'
        },
        rgroup: {
          name: ['Click here to send inquiry to Rakuten Travel Customer Support']
        },
        reset: {
          name: ['Start over the conversation'],
          submit: 'Start over the conversation'
        }
      },
      modal: {
        errorMessage: {
          required: '',
          format: ''
        },
        form: {
          email: { name: '' },
          name: { name: '' },
          tel: { name: '' },
          postalCode: { name: '', placeholder: '' },
          orderNumber: { name: '' }
        },
        message: {
          defaultMessage: ['']
        },
        waitcount: '',
      },
      seeMore: 'See more',
      sendButton: 'Send',
      statusMessage: {
        connect: ['Connect'],
        reconnect: ['Reconnect'],
        connectingOperator: ['Connecting to available operators'],
        operator: ['Start chatting with the operator'],
        disconnectOperator: ['Operator ended the chat'],
        disconnectUser: ['End of chat notification received'],
        timeoutOperator: ['Chat session has expired'],
        disconnect: ['Disconnect'],
        connectError: ['Connection error'],
        reconnectError: ['Reconnection error'],
        reconnectFailed: ['Reconnection fail'],
        tryReconnect: ['Reconnect attempt'],
        reconnecting: ['Reconnecting ({{data}} times)']
      },
      typingMessage: ' is typing',
      placeholder: {
        bot: 'Please enter your inquiry detail.\ne.g. I want to confirm the reservation and cancellation method.',
        inputDisable: 'Please select button from displayed.',
        selectCategory: 'Please select a category from the following button.',
        inputEmail: 'Please enter your email address here.',
        connectingOperator: 'Connecting to the operator. Please wait.',
        operator: 'Please enter your message.',
        disconnect: 'Thank you for using our chatbot service.\nIf you wish to chat, please open a new chat window.',
        operatorError: 'Please enter your inquiry details. Example) I want to change the registered address'
      },
      profileName: {
        bot: ['Rakuten Travel','Customer Support'],
        connectingOperator: ['Searching'],
        operator: 'Person in charge',
        disconnect: ['The connection', 'has been disconnected.']
      }
    }
  },
  zh_cn: {
    translation: {
      title: 'Rakuten Travel客户服务',
      announcementTitle: '通知',
      botError: {
        message: [
          '道歉。',
          '目前无法使用自动响应服务。',
          '请通过以下方法将您的询问发送给我们。'
        ],
        options: {
          mail: {
            titles: ['以邮件咨询']
          }
        }
      },
      cancelButton: '',
      cancelOperatorMessage: '',
      disconnectButton: {
        main: '切断连接', span: ''
      },
      fileDrop: ['请在此附上您的文件。'],
      menus: {
        operator: {
          name: ['转接到线上人工客服'],
          submit: '转接到线上人工客服'
        },
        rgroup: {
          name: ['点击此处发送咨询','到Rakuten Travel客户服务']
        },
        reset: {
          name: ['回到对话最初'],
          submit: '回到对话最初'
        }
      },
      modal: {
        errorMessage: {
          required: '',
          format: ''
        },
        form: {
          email: { name: '' },
          name: { name: '' },
          tel: { name: '' },
          postalCode: { name: '', placeholder: '' },
          orderNumber: { name: '' }
        },
        message: {
          defaultMessage: ['']
        },
        waitcount: '',
      },
      seeMore: '查看更多',
      sendButton: '发送',
      statusMessage: {
        connect: ['连接'],
        reconnect: ['再次连接'],
        connectingOperator: ['连接在线客服专员中'],
        operator: ['开始与接客服专员对话'],
        disconnectOperator: ['客服专员结束对话'],
        disconnectUser: ['已收到结束聊天通知'],
        timeoutOperator: ['对话咨询已过期'],
        disconnect: ['切断连接'],
        connectError: ['连接错误'],
        reconnectError: ['重新连接错误'],
        reconnectFailed: ['重新连接失败'],
        tryReconnect: ['重新尝试连接'],
        reconnecting: ['重新连接中({{data}}次)']
      },
      typingMessage: '正在输入',
      placeholder: {
        bot: '请输入您的查询内容。\n例）我想确认预订、取消方法',
        selectCategory: '请从以下按钮中选择一个类别。',
        inputEmail: '请在此处输入您的电邮地址。',
        connectingOperator: '连接客服专员中。请您稍后。',
        operator: '请输入咨询内容。',
        disconnect: '感谢使用Rakuten Travel在线咨询服务。\n如果需要咨询，请打开一个新的聊天窗口。',
        operatorError: '请输入您的查询详情。例）我想更改地址'
      },
      profileName: {
        bot: ['Rakuten Travel', '客户服务'],
        connectingOperator: ['搜索中'],
        operator: '负责人',
        disconnect: ['连接已中断。']
      }
    }
  },
  zh_tw: {
    translation: {
      title: '樂天旅遊客戶服務',
      announcementTitle: '通知',
      botError: {
        message: [
          '道歉。',
          '目前無法使用自動響應服務。',
          '請通過以下方法將您的詢問發送給我們。'
        ],
        options: {
          mail: {
            titles: ['以郵件諮詢']
          }
        }
      },
      cancelButton: '',
      cancelOperatorMessage: '',
      disconnectButton: {
        main: '切斷連接', span: ''
      },
      fileDrop: ['請在此附上您的文件。'],
      menus: {
        operator: {
          name: ['轉接到綫上人工客服'],
          submit: '轉接到綫上人工客服'
        },
        rgroup: {
          name: ['點擊此處發送諮詢','到樂天旅遊客戶服務']
        },
        reset: {
          name: ['回到對話最初'],
          submit: '回到對話最初'
        }
      },
      modal: {
        errorMessage: {
          required: '',
          format: ''
        },
        form: {
          email: { name: '' },
          name: { name: '' },
          tel: { name: '' },
          postalCode: { name: '', placeholder: '' },
          orderNumber: { name: '' }
        },
        message: [''],
        waitcount: '',
      },
      seeMore: '查看更多',
      sendButton: '發送',
      statusMessage: {
        connect: ['連接'],
        reconnect: ['再次連接'],
        connectingOperator: ['連接在線客服專員中'],
        operator: ['開始與接客服專員對話'],
        disconnectOperator: ['客服專員結束對話'],
        disconnectUser: ['已收到結束聊天通知'],
        timeoutOperator: ['對話諮詢已過期'],
        disconnect: ['切斷連接'],
        connectError: ['連接錯誤'],
        reconnectError: ['重新連接錯誤'],
        reconnectFailed: ['重新連接失敗'],
        tryReconnect: ['重新嘗試連接'],
        reconnecting: ['重新連接中({{data}}次)']
      },
      typingMessage: '正在輸入',
      placeholder: {
        bot: '請輸入您的查詢内容。\n例）我想確認預訂、取消方法',
        selectCategory: '請從以下按鈕中選擇一個類別。',
        inputEmail: '請在此處輸入您的電郵地址。',
        connectingOperator: '連接客服專員中。請您稍後。',
        operator: '請輸入諮詢内容。',
        disconnect: '感謝使用樂天在線諮詢服務。\n如果需要諮詢，請打開一個新的聊天窗口。',
        operatorError: '請輸入您的查詢詳情。 例）我想更改地址'
      },
      profileName: {
        bot: ['樂天旅遊客戶服務'],
        connectingOperator: ['搜索中'],
        operator: '負責人',
        disconnect: ['連接已中斷。']
      }
    }
  }
}

module.exports = {
  resources
}
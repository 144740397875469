import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/MessageActions"
import { settings } from "../config/settings"
import i18n from "../i18n"

class MessageStore extends ReduceStore {

  getInitialState() {
    return settings.message
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.isSP:
        return { 
          ...state, 
          device: action.device,
        }
      case keys.setBotIcon:
        return {
          ...state, 
          botIcon: action.botIcon,
        }
      case keys.completedUpload:
        const { id } = action
        for (let i = state.contents.length - 1; i >= 0; i--) {
          if (state.contents[i].id === id){
            state.contents[i].isSending=false;
          break;}
        }
        return {...state}
      case keys.addUserMoreMessage:
        for (let i = state.contents.length - 1; i >= 0; i--) {
          if (JSON.stringify(state.contents[i]).includes(i18n.t('seeMore'))) continue;
          if (state.contents[i].disabled) break;
          state.contents[i].disabled = true
        }
        action.content.icon = state.botIcon
        action.content.iconBg = state.bg;
        action.content.iconRadius = state.radius;
        state.contents.push({ ...action.content, balloonId: `balloon_${state.contents.length}` })
        return { ...state }
      case keys.addUserExpandMessage:
        state.contents.push({ ...action.content, balloonId: `balloon_${state.contents.length}` });
        return { ...state };
      case keys.addUserMessage:
        for (let i = state.contents.length - 1; i >= 0; i--) {
          if (state.contents[i].disabled) break;
          state.contents[i].disabled = true
        }
        state.contents.push({ ...action.content, balloonId: `balloon_${state.contents.length}` })
        return { ...state }
      case keys.addBotMessage:
        const botContents = state.contents.filter(({ from='' }) => (from === 'bot'))
        const watermark = botContents.length
        const { content: addedContent={} } = action
        const { channelData={} } = addedContent
        const { flags={} } = channelData
        const { switch_to_operator } = flags
        const timing = (watermark === 0)?'start'
        : (switch_to_operator)?'end'
        : 'middle';
        action.content.icon = state.botIcon
        action.content.iconBg = state.bg;
        action.content.iconRadius = state.radius;
        state.contents.push({ ...action.content, balloonId: `balloon_${state.contents.length}` })
        return { ...state, timing }
      case keys.addOperatorMessage:
        action.content.icon = state.operatorIcon;
        action.content.iconBg = state.bg;
        action.content.iconRadius = state.radius;
        state.contents.push({ ...action.content, balloonId: `balloon_${state.contents.length}` })
        return { ...state }

      default:
        return state
    }
  }
}

export default new MessageStore(Dispatcher)

import React, { Component } from "react"
import { Container } from "flux/utils"
import { debounce } from 'lodash'
import { ToastContainer, toast } from "react-toastify"
import jump from "jump.js"
import { isSmartPhone } from "chat-common"
import i18n from "../i18n"
import objectFitImages from 'object-fit-images';
import { useParams } from "react-router-dom";

//Containers
import MenuContainer from './MenuContainer'
import MessageContainer from './MessageContainer'
import InputContainer from './InputContainer'
//Components
import Announcement from "../components/Announcement"
import DropArea from "../components/DropArea"
import Notification from "../components/Notification"
import StatusBar from "../components/StatusBar"
import Suggestions from "../components/Suggestions"
import ScrollBtn from "../components/ScrollBtn"
import ModalWindow from "../components/ModalWindow"
//Stores
import AnnouncementStore from "../stores/AnnouncementStore"
import ConnectionStore from "../stores/ConnectionStore"
import ConversationStore from "../stores/ConversationStore"
import ChatSettingStore from "../stores/ChatSettingStore"
import DropAreaStore from "../stores/DropAreaStore"
import MessageStore from '../stores/MessageStore'
import NotificationStore from "../stores/NotificationStore"
import ScrollStore from "../stores/ScrollStore"
import SSOStore from "../stores/SSOStore"
import StatusStore from "../stores/StatusStore"
import SubmitStore from "../stores/SubmitStore"
import SuggestionStore from "../stores/SuggestionStore"
import TitleStore from "../stores/TitleStore"
import ModalWindowStore from "../stores/ModalWindowStore"
import QuestionnaireStore from "../stores/QuestionnaireStore"
import UserInfoStore from "../stores/UserInfoStore"
import ShopStore from "../stores/ShopStore"
import OrderStore from "../stores/OrderStore"
import QueueStore from "../stores/QueueStore"
import DisplayQueueCategoryStore from '../stores/DisplayQueueCategoryStore';

//Actions
import DragAreaActions from "../actions/DropAreaActions"
import MenuActions from "../actions/MenuActions"
import NotificationActions from "../actions/NotificationActions"
import { keys as connectionKeys} from "../actions/ConnectionActions"
import { keys as conversationKeys} from "../actions/ConversationActions"
import SocketActions from "../actions/SocketActions"
import ScrollActions from "../actions/ScrollActions"
import SubmitActions from "../actions/SubmitActions";
import SuggestionActions from "../actions/SuggestionActions"
import InputActions from "../actions/InputActions";
import ConversationActions from "../actions/ConversationActions";
import ShopActions from "../actions/ShopActions";
import OrderActions from "../actions/OrderActions";
import SalesforceStore from "../stores/SalesforceStore"

class App extends Component {
  static getStores() {
    return [
      AnnouncementStore,
      ConversationStore,
      DropAreaStore,
      MessageStore,
      NotificationStore,
      StatusStore,
      SubmitStore,
      SuggestionStore,
      ConnectionStore,
      ChatSettingStore,
      SSOStore,
      ScrollStore,
      TitleStore,
      ModalWindowStore,
      QuestionnaireStore,
      UserInfoStore,
      ShopStore,
      OrderStore,
      QueueStore,
      DisplayQueueCategoryStore,
      SalesforceStore,
    ]
  }

  static calculateState(prevState) {
    return {
      announcement: AnnouncementStore.getState(),
      conversation: ConversationStore.getState(),
      droparea: DropAreaStore.getState(),
      message: MessageStore.getState(),
      notification: NotificationStore.getState(),
      status: StatusStore.getState(),
      submit: SubmitStore.getState(),
      suggestions: SuggestionStore.getState(),
      connection: ConnectionStore.getState(),
      chatSetting: ChatSettingStore.getState(),
      sso: SSOStore.getState(),
      scroll: ScrollStore.getState(),
      title: TitleStore.getState(),
      modalWindow: ModalWindowStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      userInfo: UserInfoStore.getState(),
      shop: ShopStore.getState(),
      order: OrderStore.getState(),
      queue: QueueStore.getState(),
      displayQueueCategory: DisplayQueueCategoryStore.getState(),
      salesforce: SalesforceStore.getState()
    }
  }

  componentDidMount(){
    objectFitImages();
    document.addEventListener('click',(e) => {
      if(e.target.localName === 'a'){
        SocketActions.linkOpened({targetLink: e.target.href})
      }
    })
    const lang = this.getLangParam()
    i18n.changeLanguage(lang)
    NotificationActions.setLang(lang)
    var event;
    if (typeof(window.Event) === 'function') {
      event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
      document.dispatchEvent(event);
    } else {
      try {
        event = document.createEvent('Event');
        event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
        document.dispatchEvent(event);
      } catch (e) {}
    }
    document.title = i18n.t(this.state.title)
  }

  componentDidUpdate(prevProps, prevState){
    //const ichibaChatReferrerId = this.props.match.params.ichiba_chat_referrer_id
    if(
    ( this.state.conversation.type !== conversationKeys.start
    && !this.state.chatSetting.chatSessionId)
    || (prevState.connection.type !== this.state.connection.type
    && prevState.connection.type !== connectionKeys.connect
    && this.state.connection.type === connectionKeys.connect)){
      this.handleConnect(prevState)
    }
    if(this.state.announcement.title !== prevState.announcement.title) toast(<Announcement {...this.state.announcement}/>, { position: "bottom-right", className: "announcement-toast" })
    if(prevState.message !== this.state.message) this.scrollToBottom()
  }

  handleConnect = debounce((prevState)=>{
    const { conversationCase='', shopId='', orderNumber='', healthChecker='' } = this.props?.params || {};
    const sessionId = this.state.chatSetting.chatSessionId
    const {opponent} = this.state.submit
    const lang = this.getLangParam()
    const location = window.location;
    const facilityInfo = {
      facilityNumber: this.props?.spn || '',
      zoning: this.props?.Zoning || ''
    }
    SocketActions.startBot(opponent, conversationCase, 'asdf', shopId, orderNumber, sessionId, healthChecker, location.href, location.pathname, document.referrer, this.state.sso.id, lang, facilityInfo)
    ConversationActions.start();
    ShopActions.load(shopId);
    OrderActions.load(orderNumber);
  },1000)

  getLangParam = () => {
    const resourcesLang = Object.keys(i18n.options.resources)
    const lang = new URLSearchParams(window.location.search).get('lang')
    return (lang && resourcesLang.indexOf(lang) >= 0) ? lang : 'ja'
  }

  scrollToBottom = () => {
    const { contents=[] } = this.state.message
    const balloonId = (contents.length)?`.${contents[contents.length - 1].balloonId}` : undefined;
    this.scroll(balloonId)
  }

  scroll = debounce((className, duration = 600) => {
    if (this.isScrollSkip()) return
    let jumpOption = {duration}
    if (window.innerWidth < 700) {
      let header = document.getElementsByClassName('chatHeader')[0].getBoundingClientRect().height
      Object.assign(jumpOption,{offset: -header - 10})
    }
    jump(className,jumpOption)
  },100)

  isScrollSkip = () => {
    const { contents=[] } = this.state.message
    const latestContent = (contents.length) ? contents[contents.length - 1] : {}
    if (latestContent?.from !== 'bot') return

    // 最新1つ前のcontentのflagsを確認
    const botContents = contents.filter(content => (content.from === 'bot'))
    const latestIndex = (botContents.length) ? botContents.length - 1 : null
    const targetContent = (latestIndex) ? botContents[latestIndex - 1] : {}
    const { channelData={} } = targetContent || {}
    return channelData?.flags?.triggerFeedBack || false
  }

  onCarouselPrev = () => {
    SuggestionActions.prev()
  }
  onCarouselNext = () => {
    SuggestionActions.next()
  }

  clickSuggestion = (event) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place:'suggestion', text: event.target.value, action:'post-message'})
    }
    SubmitActions.submit(event.target.value, 'suggestion', this.state.submit, this.state.message.contents, this.state.questionnaire)
  }

  scrollUpdate = debounce(ScrollActions.scroll,100)

  clickMessageArea = () => {
    if(!isSmartPhone(navigator.userAgent)){
      InputActions.focus()
    }
    MenuActions.close()
  }
  clickMessageArea = () => {
    MenuActions.close()
  }
  clickMenuArea = () => {
    if(!isSmartPhone(navigator.userAgent)){
      InputActions.focus()
    }
  }
  onDragEnter = (e) => {
    DragAreaActions.show()
  }
  onClickNotification = () => {
    SocketActions.report({timing:'', place:'notification', text:'', action:'click'});
    window.focus();
  }
  rootClass = (isSmartPhone(navigator.userAgent))?'sp':'pc';
  render() {
    return (
    <div className={this.rootClass} onClick={this.clickMenuArea} onDragEnter={this.onDragEnter}>
      <MenuContainer />
      <div className="u-clearfix" onClick={this.clickMessageArea}>
        <StatusBar {...this.state.status} queue={this.state.queue} displayQueueCategory={this.state.displayQueueCategory} sid={this.state.salesforce.id}/>
        <MessageContainer />
        <DropArea {...this.state.droparea} />
        <Suggestions {...this.state.suggestions} clickSuggestion={this.clickSuggestion} onCarouselPrev={this.onCarouselPrev} onCarouselNext={this.onCarouselNext} />
        <InputContainer />
        <ScrollBtn {...this.state.scroll} scroll={this.scrollUpdate} onClick={this.scrollToBottom}/>
        <Notification {...this.state.notification} onClick={this.onClickNotification}/>
        <ToastContainer type="default" autoClose={180000} hideProgressBar={true} newestOnTop={true} closeOnClick={false} pauseOnHover={false}
        className="announcement-toast-container" bodyClassName="announcement-toast-detail" style={{bottom: "65px", zIndex: 0}} />
        <ModalWindow {...this.state}/>
      </div>
  </div>)
  }
}

const AppContainer = Container.create(App)
// There is a only way to get params that use custom hook called useParams
// To use the hooks on App component, we convert App to function component.
// To convert this, we have to think about state management design because flux/utils accepts only class component.
export default (props) => (<AppContainer {...props} params={useParams()} />)

import Dispatcher from "../Dispatcher"

export const keys = {
  initial: Symbol("upload:initial"),
  show: Symbol("upload:show"),
  hide: Symbol("upload:hide"),
  changeButton: Symbol("update:change-button"),
  disable: Symbol("upload:disable"),
  enable: Symbol("upload:enable"),
}

export default {
  show() {
    Dispatcher.dispatch({
      type: keys.show,
    })
  },
  hide() {
    Dispatcher.dispatch({
      type: keys.hide,
    })
  },
  changeButton(isSP) {
    Dispatcher.dispatch({
      type: keys.changeButton,
      icon: (isSP) ? "/img/sendimage_icon_sp.png" : "/img/sendfile_icon_pc.png",
      accept: (isSP) ? "image/*" : "*/*"
    })
  },
  disable() {
    Dispatcher.dispatch({
      type: keys.disable,
    })
  },
  enable(state) {
    Dispatcher.dispatch({
      type: keys.enable,
    })
  }
}
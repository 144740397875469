import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/DropAreaActions"
import { settings } from "../config/settings"

class DropAreaStore extends ReduceStore {

  getInitialState() {
    return settings.droparea
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.enable:
        return { ...state, disabled: false}
      case keys.disable:
        return { ...state, disabled: true}
      case keys.show:
        return { ...state, visible: true}
      case keys.hide:
        return { ...state, visible: false}
      default:
        return state
    }
  }
}

export default new DropAreaStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/QuestionnaireActions"

class QuestionnaireStore extends ReduceStore {

  getInitialState() {
    return {}
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return  {...state, ...action.item}
      default:
        return state
    }
  }
}

export default new QuestionnaireStore(Dispatcher)

import React, { Component } from "react"
import SSOStore from '../stores/SSOStore'
import SSOActions from '../actions/SSOActions'

class Auth extends Component {

  static getStores() {
    return [
      SSOStore,
    ]
  }

  static calculateState(prevState) {
    return {
      sso: SSOStore.getState(),
    }
  }
  componentDidMount(){
    this.setState({  isAuthenticated: 'try' })
    this.login();
  }

  async login(){
    const id = this.props.id
    if(id){
      SSOActions.login(id)
      this.setState({  isAuthenticated: 'success' })
    } else {
      window.location.assign('https://mainmenu.rms.rakuten.co.jp/?act=app_login_error')
    }
  }

  render(){
    if(this.state && this.state.isAuthenticated === 'success') {
      return <>{this.props.children}</>
    } else {
      return <div></div>
    }
  }

}

export default Auth
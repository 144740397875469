import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/SuggestionActions"
import { settings } from "../config/settings"

class SuggestionsStore extends ReduceStore {

  getInitialState() {
    return settings.suggestion
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return {...state, device: action.device}
      case keys.show:
        return {...state, items: action.items, index:0, posX: "16px", inlineStyle: {transform: "translateX(0)"}}
      case keys.clear:
        return {...state, items: []}
      case keys.next:{
        let currentIndex = state.index;
        const nextIndex =(state.index !== state.items.length - 1 )?++currentIndex:currentIndex;
        const nextPosX = document.getElementById(`suggestion_item_${nextIndex}`).offsetLeft * -1 + "px"
        const inlineStyle = { transform: `translateX(${nextPosX})`}
        //const nextx=document.getElementById(`suggestion_item_${nextIndex}`).getBoundingClientRect().x
        //scroll
        return {
          ...state,
          index: nextIndex,
          posX: nextPosX,
          inlineStyle: inlineStyle
        }
      }
      case keys.prev:{
        let currentIndex = state.index;
        const nextIndex =(state.index !== 0 )?--currentIndex:currentIndex;
        const nextPosX = document.getElementById(`suggestion_item_${nextIndex}`).offsetLeft * -1 + "px"
        const inlineStyle = { transform: `translateX(${nextPosX})`}
        return {
          ...state,
          index: nextIndex,
          posX: nextPosX,
          inlineStyle: inlineStyle
        }
      }
      case keys.setDefaultItem:
        return {...state, defaultItems: action.defaultItems}
      case keys.showDefaultItem:
        const { defaultItems=[] } = state || {};
        return {...state, items: defaultItems||[] }
      default:
        return state
    }
  }
}

export default new SuggestionsStore(Dispatcher)

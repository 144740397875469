import React from "react"
import i18n from "../i18n"

export default ({onClick=()=>{}, disabled=false, grayOut=false}) => {
  const grayOutClass = (grayOut) ? 'inputBar__submit--grayOut' : ''
  return (
  <div className="inputBar__grid inputBar__grid--submit">
    <button className={`inputBar__submit ${grayOutClass}`} onClick={onClick} disabled={disabled}>{i18n.t('sendButton')}</button>
  </div>
)}

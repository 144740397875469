import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("status:initialize"),
  isCompleted: Symbol("status:is-completed"),
  isProcessing: Symbol("status:is-processing"),
  isError: Symbol("status:is-error"),
  isAlert: Symbol("status:is-alert")
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize,
      text:'',
      classification: ''
    })
  },
  isCompleted(text){
    Dispatcher.dispatch({
      type: keys.isCompleted,
      text,
      classification: 'is-complete'
    })
  },
  isProcessing(text,data){
    Dispatcher.dispatch({
      type: keys.isProcessing,
      text,
      data,
      classification: 'is-processing'
    })
  },
  isError(text){
    Dispatcher.dispatch({
      type: keys.isError,
      text,
      classification: 'is-error'
    })
  },
  isAlert(text){
    Dispatcher.dispatch({
      type: keys.isAlert,
      text,
      classification: 'is-alert'
    })
  }
}
import React from "react"
import i18n from "../i18n"

export default ({ personel }) => {
  const translatedParsonel = (personel) ? i18n.t(personel) : personel
  return (
  <div className="profile__grid profile__grid--03">
    <span className="profile__personnel">{translatedParsonel}</span>
  </div>
)}

import Dispatcher from "../Dispatcher"
import MessageActions from "../actions/MessageActions";
import SocketActions from "./SocketActions"

export const keys = {
  show: Symbol("modal:show"),
  close: Symbol("modal:close"),
  submit: Symbol("modal:submit"),
}

export default {
  show() {
    Dispatcher.dispatch({
      type: keys.show,
      isOpen: true,
    })
  },
  close(opponent, info, userText) {
    Dispatcher.dispatch({
      type: keys.close,
      isOpen: false,
    })
    MessageActions.addUserMessage(userText);
    SocketActions.postDetail(opponent, info);
  },
  submit(opponent, info, contents, questionnaire) {
    Dispatcher.dispatch({
      type: keys.submit,
      isOpen: false,
    })
    SocketActions.postDetail(opponent, info, contents, questionnaire);
  },
}

import Dispatcher from "../Dispatcher"

export const keys = {
  initial: Symbol("availability:initial"),
  updated: Symbol("availability:updated"),
}

export default {
  initial(){
    Dispatcher.dispatch({
      type: keys.initial,
    })
  },
  updated(availability){
    Dispatcher.dispatch({
      type: keys.updated,
      availability,
    })
  },
}
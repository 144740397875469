import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/SalesforceActions"
import { settings } from "../config/settings"

class SalesforceStore extends ReduceStore {
  getInitialState() {
    return settings.salesforce
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return {...state, id: action.id}
      default:
        return state
    }
  }
}

export default new SalesforceStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ConnectionActions"
import { settings } from "../config/settings"

class ConnectionStore extends ReduceStore {

  getInitialState() {
    return { type: keys.disconnect }
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.connect:
      case keys.error:
      case keys.disconnect:
      case keys.reconnect:
      case keys.reconnectAttempt:
      case keys.reconnecting:
      case keys.reconnectError:
      case keys.reconnectFailed:
        return action

      default:
        return state
    }
  }
}

export default new ConnectionStore(Dispatcher)

import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("displayQueueCategory:set"),
}

export default {
  set(formKey){
    Dispatcher.dispatch({
      type: keys.set,
      formKey,
    })
  },
}
import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/UploadActions"
import { settings } from "../config/settings"

class UploadStore extends ReduceStore {

  getInitialState() {
    return settings.upload
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.show:
        return {...state,  isDisplay: true}
      case keys.hide:
        return {...state,  isDisplay: false}
      case keys.changeButton:
        return {
          ...state,
          icon: action.icon,
          accept: action.accept,
        }
      case keys.enable:
        return {...state, disabled: false}
      case keys.disable:
        return {...state, disabled: true}
      default:
        return state
    }
  }
}

export default new UploadStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/BotErrorActions"
import { settings } from "../config/settings"

class BotErrorStore extends ReduceStore {
  getInitialState() {
    return settings.botError
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return {...state, device: action.device}
      case keys.show:
      case keys.hide:
        return {...state, isDisplay:action.isDisplay}
      default:
        return state
    }
  }
}

export default new BotErrorStore(Dispatcher)

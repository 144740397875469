import React, { useState } from "react"
import CustomReactMarkdown from "./CustomReactMarkdown"

export default ({text , clickButton, disabled, value=text, type}) => {
  const [buttonDisabled, setDisabled] = useState(false)

  const onClick = (event) => {
    setDisabled(true)
    clickButton(event, text, value, type)
  }

  return (
    <button className="radiusBtn radiusBtn--light" onClick={onClick} value={value} disabled={buttonDisabled || disabled}>
      <CustomReactMarkdown children={text} />
    </button>
  )
}

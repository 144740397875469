import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/InputActions"
import { settings } from "../config/settings"

class InputStore extends ReduceStore {

  getInitialState() {
    return settings.input
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return settings.input;
      case keys.changePlaceholder:
        return {...state, placeholder: action.placeholder}
      case keys.change:
      case keys.input:
        return {...state, value: action.value}
      case keys.disable:
      case keys.enable:
        return {...state, disabled: action.disabled}
      case keys.clear:
        action = {...state, value:''}
        return action
      default:
        return state
    }
  }
}

export default new InputStore(Dispatcher)

import React from "react";

export default ({ type, id, value, required, maxLength, style, placeholder, onChange, onBlur, isSpace }) => (
  <input
    id={id}
    value={value}
    style={style}
    type={type}
    required={required}
    className={`modalInput__field ${isSpace ? 'modalInput__space' : ''}`}
    placeholder={placeholder}
    maxLength={maxLength}
    onChange={onChange}
    onBlur={onBlur}
  />
)

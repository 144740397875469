import React from "react"

import MessageActions from "../actions/MessageActions";
import SocketActions from "../actions/SocketActions";

export default ({ isDisplay, icon, fileimg, accept, disabled }) => {
  const onFileSelected = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const { name:filename, type:filetype, size:filesize } = file
      const id = parseInt(new Date() / 1000, 10)
      const reader = new FileReader();
      reader.onload = (event) => {
        if(process.env.REACT_APP_SERVICE!=='merchant'){
          if (file.type.startsWith("image")) {
            MessageActions.addUserMessage(file.name, event.target.result, true, false, id)
          } else {
            MessageActions.addUserMessage(file.name, fileimg, true, true, id)
          }
        }
        SocketActions.uploadFile(filename, filetype, filesize, event.target.result, id)
      };
      if(process.env.REACT_APP_SERVICE==='merchant'){
        reader.readAsArrayBuffer(file)
      }else{
        reader.readAsDataURL(file);
      }
    }
  }

  const onFileSelector = () => {
    document.getElementById('upfile').click()
  }

  return (
      isDisplay ?
        ( <div className="inputBar__grid inputBar__grid--fileChooser">
            <input className="inputBar__fileChooser" type="file" name="upfile" id="upfile" accept={accept} onChange={onFileSelected} />
            <img className="inputBar__fileChooserImg" id="thumbnail" src={icon} onClick={onFileSelector} alt="" disabled={disabled} />
          </div>)
        : (<div></div>)
  )
}
    

import React from "react"
import RadiusBtn from "./RadiusBtn"
import RadiusBtnLight from "./RadiusBtnLight"
import SquareBtnBorderedArrow from "./SquareBtnBorderedArrow"

export default ({buttons, clickButton, clickLink, disabled}) => (
  <div className="multipleChoiceQuestion">
    <ul className="multipleChoiceQuestion__items">
      {buttons.map((button, index) => {
        return (button.more)
          ? (
            <li className="multipleChoiceQuestion__item" key={index}>
              <RadiusBtnLight text={button.title} clickButton={clickButton} disabled={disabled} type={button.type}/>
            </li>
          )
          : (button.type === "openUrl")
            ? (
              <li className="multipleChoiceQuestion__item" key={index}>
                <SquareBtnBorderedArrow href={button.value} text={button.title} clickLink={clickLink} />
              </li>
            )
            : (button.category)
              ? (
                <li className="multipleChoiceQuestion__item" key={index}>
                  <RadiusBtn text={button.title} value={button.value} mdValue={button.mdValue} clickButton={clickButton} disabled={disabled} type={button.type}/>
                </li>)
              : (
                <li className="multipleChoiceQuestion__item" key={index}>
                  <RadiusBtn text={button.title} value={button.value} mdValue={button.mdValue} clickButton={clickButton} disabled={disabled} type={button.type}/>
                </li>
              )
      })}
    </ul>
  </div>
)

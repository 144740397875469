import React from 'react';
import UserInfoInputField from "./UserInfoInputField";

export default ({ userInfo={}, onChange, onBlur, errMsgs }) => {
  const { formKey='', categorization={}, inputs={} } = userInfo;
  const inputNameArray = categorization[formKey] || [];
  const inputInfoArray = inputNameArray
    .filter(({ input }) => inputs[input])
    .map(({ input, required }) => {
      const { type, id, maxLength, isTextArea, style } = inputs[input] || {};
      const value = userInfo[id] || '';
      return { type, id, value, required, maxLength, isTextArea, style, name: input };
    });
  return (
      <div>
        {inputInfoArray.map((inputInfo, index) => (
            <UserInfoInputField
              errMsg={errMsgs[inputInfo.id]}
              type={inputInfo.type}
              id={inputInfo.id}
              name={inputInfo.name}
              value={inputInfo.value}
              required={inputInfo.required}
              maxLength={inputInfo.maxLength}
              isTextArea={inputInfo.isTextArea}
              style={inputInfo.style}
              onChange={onChange}
              onBlur={onBlur}
              key={index} />
        ))}
      </div>
  );
}

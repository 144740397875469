import React from "react"
import i18n from "../i18n"

export default ({onClick ,isDisplay, disabled}) => {
  const fontSmallClass = (i18n.language === 'en') ? 'fontSmall' : ''
  return (
    (isDisplay)
    ? (<div className="disconnectBtn">
          <button onClick={onClick} disabled={disabled} >
            <p className={fontSmallClass}>
              {i18n.t('disconnectButton.main')}<span>{i18n.t('disconnectButton.span')}</span>
            </p>
          </button>
        </div>)
    : (<div></div>)
)}

import React, { Component } from "react"
import { Container } from "flux/utils"

import DisconnectStore from '../stores/DisconnectStore'
import MenuStore from '../stores/MenuStore'
import MessageStore from '../stores/MessageStore'
import SubmitStore from '../stores/SubmitStore'

import DisconnectButton from '../components/DisconnectButton'
import ProfileContainer from './ProfileContainer'
import MenuButton from '../components/MenuButton'
import MenuList from '../components/MenuList'

import MenuActions from '../actions/MenuActions'
import SubmitActions from "../actions/SubmitActions";
import InputActions from "../actions/InputActions";
import DisconnectActions from "../actions/DisconnectActions";
import SocketActions from '../actions/SocketActions'
import QuestionnaireStore from "../stores/QuestionnaireStore";
import ShopStore from '../stores/ShopStore';
import OrderStore from '../stores/OrderStore';
import QueueStore from '../stores/QueueStore';
import BotMaintenanceStore from "../stores/BotMaintenanceStore";
import DisplayQueueCategoryStore from '../stores/DisplayQueueCategoryStore';

import { calcWaitCount } from '../utils';

class MenuContainer extends Component {
  static getStores() {
    return [
      DisconnectStore,
      MenuStore,
      MessageStore,
      QuestionnaireStore,
      SubmitStore,
      ShopStore,
      OrderStore,
      QueueStore,
      BotMaintenanceStore,
      DisplayQueueCategoryStore,
    ]
  }

  static calculateState(prevState) {
    return {
      disconnect: DisconnectStore.getState(),
      menu: MenuStore.getState(),
      message: MessageStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      submit: SubmitStore.getState(),
      shop: ShopStore.getState(),
      order: OrderStore.getState(),
      botMaintenance: BotMaintenanceStore.getState(),
      queue: QueueStore.getState(),
      displayQueueCategory: DisplayQueueCategoryStore.getState(),
    }
  }

  onClickMenuButton = (event) => {
    event.stopPropagation()
    MenuActions.toggle()
  }

  onSubmit = (text) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place:'navigation', text, action:'post-message'})
    }
    SubmitActions.submit(text, 'button', this.state.submit, this.state.message.contents, this.state.questionnaire)
  }
  openUrl = (url, target) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place:'navigation', text: url, action:'open-link'})
    }
    return window.open(url, target).focus();
  }
  focus = () => {
    InputActions.focus()
  }
  onDisconnect = () => {
    DisconnectActions.disconnect()
  }

  render() {
    const waitCount = calcWaitCount(this.state.queue, this.state.displayQueueCategory);

    return (
      <header className="chatHeader" id="chatHeader" onClick={this.focus}>
        <div className="chatHeader__inner">
          <ProfileContainer />
          <DisconnectButton {...this.state.disconnect} onClick={this.onDisconnect} />
          <div className={`hamburgerMenu hamburgerMenu__interval`}>
            <MenuButton {...this.state.menu} onClick={this.onClickMenuButton} />
            <MenuList {...this.state.menu} shop={this.state.shop} order={this.state.order}
              botMaintenance={this.state.botMaintenance} queue={waitCount} onSubmit={this.onSubmit} openUrl={this.openUrl} />
          </div>
        </div>
      </header>)
  }
}

export default Container.create(MenuContainer)
import Dispatcher from "../Dispatcher"
import Socket from "../Socket"

export const keys = {
  start: Symbol("socket:start"),
  restart: Symbol("socket:restart"),
  report: Symbol("socket:report"),
  switch: Symbol("socket:switch"),
  switchRequest: Symbol("socket:switch-request"),
  inputMailAddress: Symbol("socket:input-mail-address"),
  postMessage: Symbol("socket:post-mssage"),
  postDetail: Symbol("socket:post-detail"),
  getMessage: Symbol("socket:get-message"),
  getFaq: Symbol("socket:get-faq"),
  getSuggestion: Symbol("socket:get-suggestion"),
  checkChatDisconnect: Symbol("socket:check-chat-disconnect"),
  close: Symbol("socket:close"),
  uploadFile: Symbol("socket:upload-file"),
  uploadFileSizeOver: Symbol("socket:upload-file-size-over"),
  linkOpened: Symbol("socket:link-opened")
}

export default {
  startBot(opponent='bot', conversationCase='', clientId, shopId='', orderNumber='', chatSessionId, healthChecker='test', pageUrl, urlPath, referrer, id, lang='ja', facilityInfo) {
    Dispatcher.dispatch({
      type: keys.start
    })

    Socket.startBot(opponent, conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityInfo)
  },
  restartBot(){
    Dispatcher.dispatch({
      type: keys.restart
    })
    Socket.restartBot();
  },
  report({timing="",place="",text="",action=""}){
    Socket.report({timing, place, text, action})
  },
  postMessage(opponent, text, inputType, contents, questionnaire) {
    Dispatcher.dispatch({
      type: keys.postMessage,
      text,
    })
    Socket.postMessage(opponent, text, inputType, contents, questionnaire)
  },
  linkOpened({targetLink}){
    Dispatcher.dispatch({
      type: keys.linkOpened,
      targetLink,
    })
    Socket.linkOpened({targetLink})
  },

  postDetail(opponent, info, contents=[], questionnaire={}) {
    Dispatcher.dispatch({
      type: keys.postDetail,
      info,
    })
    Socket.postDetail(opponent, info, contents, questionnaire)
  },

  reportUserAction({timing,place,content,action}) {
    Socket.reportUserAction({timing,place,content,action})
  },

  sendUserTextAsSneakPeak(text){
    Socket.sendUserTextAsSneakPeak(text)
  },

  uploadFile(filename, filetype, filesize, data_uri, id) {
    Dispatcher.dispatch({
      type: keys.uploadFile,
      filename,
      filetype,
      filesize,
      data_uri,
      id,
    })

    Socket.uploadFile(filename, filetype, filesize, data_uri, id)
  },

  uploadFileSizeOver(id) {
    Dispatcher.dispatch({
      type: keys.uploadFileSizeOver,
    })

    Socket.uploadFileSizeOver(id)
  },

  getFaq(text) {
    Dispatcher.dispatch({
      type: keys.getFaq,
      text,
    })

    Socket.getFaq(text)
  },

  getSuggestion(text) {
    Dispatcher.dispatch({
      type: keys.getSuggestion,
      text,
    })

    Socket.getSuggestion(text)
  },
  checkChatDisconnect() {
    Dispatcher.dispatch({
      type: keys.checkChatDisconnect,
    })
    Socket.checkChatDisconnect()
  },

  sendUserTyping(){
    Socket.sendUserTyping()
  },
  sendUserNotTyping(){
    Socket.sendUserNotTyping()
  },

  close() {
    Dispatcher.dispatch({
      type: keys.close,
    })

    Socket.close()
  },

  //test
  clearSession(sessionId){
    Socket.clearSession(sessionId)
  }
}

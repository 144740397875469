import React from "react"
import i18n from "../i18n"

export default ({ name, isDisplay }) => {
  const translatedName = (i18n.exists(name)) ? i18n.t(name) : name
  return (
    (isDisplay)
    ? (<div className="typingMessage">{translatedName}{i18n.t('typingMessage')}<i>.</i><i>.</i><i>.</i></div>)
    : (<div></div>)
)}

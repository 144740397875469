import React from 'react';

export default ({ id, style, required, onChange, onBlur, checkContent, isSpace }) => (
  <div className={`modalInput__checkBoxField ${isSpace ? 'modalInput__space' : ''}`}>
    <input
      id={id}
      style={style}
      className={`modalInput__checkBox`}
      type="checkbox"
      required={required}
      onChange={onChange}
      onBlur={onBlur}
    />
    <p className={'modalInput__checkBoxContent'}>{checkContent}</p>
  </div>
);
import React, { Component } from "react"
import { Container } from "flux/utils"
import { isSmartPhone } from 'chat-common'

import SubmitActions from "../actions/SubmitActions"

import LAAvailabilityStore from "../stores/LAAvailabilityStore"
import BotErrorStore from '../stores/BotErrorStore'
import MessageStore from '../stores/MessageStore'
import QuestionnaireStore from '../stores/QuestionnaireStore'
import SubmitStore from '../stores/SubmitStore'
import ShopStore from '../stores/ShopStore'
import OrderStore from '../stores/OrderStore'

import BotError from "../components/BotError"
import UserMessage from "../components/UserMessage";
import BotMessage from "../components/BotMessage"
import OperatorMessage from "../components/OperatorMessage"
import MessageActions from "../actions/MessageActions";
import SocketActions from "../actions/SocketActions";

class MessageContainer extends Component {
  static getStores() {
    return [
      LAAvailabilityStore,
      BotErrorStore,
      MessageStore,
      QuestionnaireStore,
      SubmitStore,
      ShopStore,
      OrderStore
    ]
  }

  static calculateState(prevState) {
    return {
      laAvailability: LAAvailabilityStore.getState(),
      botError: BotErrorStore.getState(),
      message: MessageStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      submit: SubmitStore.getState(),
      shop: ShopStore.getState(),
      order: OrderStore.getState(),
    }
  }

  componentDidMount(){
    MessageActions.isSP(isSmartPhone(navigator.userAgent));
  }

  clickButton = (event, text, value, type, mdValue) => {
    value = value || event.target.value || event.target.getAttribute('value')
    type   = type || event.target.getAttribute('type')
    switch(type){
      case 'imBack':
      case 'more':
        return this.postMessage(value, 'message')
      case 'expand':
        return this.clickExpandButton(text, value, mdValue)
      default:
        return this.postMessage(value, 'message')
    }
  }

  clickExpandButton = (question, answer, originalAnswer) => {
    const watermark = this.state.message.contents.length
    const timing = (watermark === 0)?'exception':this.state.message.timing;
    MessageActions.addUserExpandMessage(question);
    MessageActions.addBotExpandMessage(answer);

    // this report is needed to call discovery_replied api
    SocketActions.report({timing, place:'message', text: {input: question, output: originalAnswer || ''}, action:'expand'});

    // === find and add(display) feedback content (we will refactor this)
    const contents = [ ...this.state.message.contents ];
    const reversedContents = contents.reverse();
    // search target content that includes originalAnswer(mdValue)
    const targetContent = reversedContents.find(content => {
      const { attachments } = content;
      if (!attachments) return false;
      const mdValues = attachments.reduce((prev, attachment) => {
        const { content } = attachment;
        if (!content) return prev;
        const { buttons } = content;
        if (!buttons) return prev;
        return [ ...prev, ...buttons.map(button => button.mdValue) ];
      }, []);
      return mdValues.includes(originalAnswer);
    });
    if (!targetContent || !targetContent.channelData || !targetContent.channelData.flags) return;
    const { askfeedback, discovery } = targetContent.channelData.flags;
    const existAskFeedback = typeof askfeedback !== 'undefined';
    // for ichiba
    if (existAskFeedback && !askfeedback) return;
    // for shinku
    if (!existAskFeedback && !discovery) return;
    const targetAttachments = targetContent.attachments;
    const attachment = targetAttachments.find(attachment => {
      return attachment.content && attachment.content.feedback
    })
    const {buttons, text} = attachment.content.feedback
    if( buttons || text )MessageActions.addBotFeedbackMessage(buttons, text)
    // ===
  }

  postMessage = (text, place) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.contents.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place:'message', text, action:'post-message'})
    }
    SubmitActions.submit( text, 'button', this.state.submit, this.state.message.contents, this.state.questionnaire);
  }

  //this function is not used
  clickErrorButton = ( value ) => {
    this.postMessage(value, 'exception')
  }

  clickLink = (href) => {
    SocketActions.report({timing:'', place:'message', text: href, action: 'open-link'});
  }

  targetDevice = (isSmartPhone(navigator.userAgent)) ? 'sp' : 'pc';

  render(){
    return (
      <div className="stream u-clearfix suggest-margin" id="message_stream">
        <div className="stream__inner">
          <BotError {...this.state.botError} shop={this.state.shop} order={this.state.order} clickButton={this.postMessage} clickLink={this.clickLink}/>
          {this.state.message.contents.map((content, index) =>
            (content.from === 'user')? <UserMessage {...content} key={index}/>
            :(content.from === 'bot') ? <BotMessage {...content} availability={this.state.laAvailability} clickButton={this.clickButton} clickLink={this.clickLink} key={index} device={this.targetDevice} watermark={index}/>
            :(content.from === 'operator') ? <OperatorMessage {...content} key={index}/>
            :<div></div>
          )}
        </div>
      </div>)
  }
}

export default Container.create(MessageContainer)
import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/PersonelActions"
import { settings } from "../config/settings"

class PersonelStore extends ReduceStore {

  getInitialState() {
    return settings.personel
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return {
          personel: action.personel
        }
      default:
        return state
    }
  }
}

export default new PersonelStore(Dispatcher)

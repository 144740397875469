import Dispatcher from "../Dispatcher"

export const keys = {
  connect: Symbol("connection:connect"),
  error: Symbol("connection:error"),
  disconnect: Symbol("connection:disconnect"),
  reconnect: Symbol("connection:reconnect"),
  reconnectAttempt: Symbol("connection:reconnect-attempt"),
  reconnecting: Symbol("connection:reconnecting"),
  reconnectError: Symbol("connection:reconnect-error"),
  reconnectFailed: Symbol("connection:reconnect-failed"),
}

export default {

  connect() {
    Dispatcher.dispatch({
      type: keys.connect,
    })
  },

  error(error) {
    Dispatcher.dispatch({
      type: keys.error,
      error,
    })
  },

  disconnect() {
    Dispatcher.dispatch({
      type: keys.disconnect,
    })
  },

  reconnect(number) {
    Dispatcher.dispatch({
      type: keys.reconnect,
      number,
    })
  },

  reconnectAttempt() {
    Dispatcher.dispatch({
      type: keys.reconnectAttempt,
    })
  },

  reconnecting(number) {
    Dispatcher.dispatch({
      type: keys.reconnecting,
      number,
    })
  },

  reconnectError(error) {
    Dispatcher.dispatch({
      type: keys.reconnectError,
      error,
    })
  },

  reconnectFailed() {
    Dispatcher.dispatch({
      type: keys.reconnectFailed,
    })
  },
}

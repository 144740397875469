import Dispatcher from "../Dispatcher"

export const keys = {
  change: Symbol("botMaintenance:change"),
}

export default {
  change(isMaintenance){
    Dispatcher.dispatch({
      type: keys.change,
      isMaintenance,
    })
  },
}
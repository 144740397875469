import React, { useState, useEffect } from 'react';
import EmailValidator from 'email-validator';
import i18n from "../i18n";
import { settings } from "../config/settings";
import { changeToCustomHTML } from "chat-common";
import { calcWaitCount } from '../utils';
import CustomReactMarkdown from './CustomReactMarkdown';

import UserInfoInputList from "./UserInfoInputList";

const { language: lang } = i18n;
const modalIcon = settings.modalWindow.icon.logoSrc[lang];

const isDisplayedWaitCount = (displayQueueCategory = '') => {
  switch (settings.calcWaitCountType) {
    case 'ALL': return true;
    case 'CATEGORY': return displayQueueCategory ? true : false;
    default: return false;
  }
}

const createWaitCountMessage = (queue, displayQueueCategory) => {
  const waitCountMessage = i18n.t('modal.waitcount');
  if (!isDisplayedWaitCount(displayQueueCategory)) return null;
  if (!waitCountMessage.includes('$COUNT') && !waitCountMessage.includes('$MORE'))
    return (<p>{waitCountMessage}</p>);
  const waitCount = calcWaitCount(queue, displayQueueCategory);
  const maxQueueCount = process.env.REACT_APP_MAX_QUEUE_COUNT
  const countMessage = (queue && maxQueueCount && waitCount >= maxQueueCount) ? maxQueueCount : waitCount
  const moreMessage = queue && waitCount >= maxQueueCount ? '以上' : ''
  const fixedMessage = waitCountMessage
    .replace(/\$COUNT/, countMessage)
    .replace(/\$MORE/, moreMessage);
  return (<p>{fixedMessage}</p>);
}

const convertIdIntoValidatePattern = (id='') => {
  switch(id) {
    case 'modalPostalCode':
      return '^([0-9]|[０-９]){7}$';
    case 'modalTel':
      return '^([0０]([0-9０-９][-|‐|ー|－|―|−]?[0-9０-９]{4}|[0-9０-９]{2}[-|‐|ー|－|―|−]?[0-9０-９]{3}|[0-9０-９]{3}[-|‐|ー|－|―|−]?[0-9０-９]{2}|[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９])[-|‐|ー|－|―|−]?[0-9０-９]{4}|[0０][789７８９][0０][--|‐|ー|－|―|−]?[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９]{4})$';
    case 'modalOrderNumber':
      return '^([0-9]|[０-９]){6}(-|‐|ー|－|―)([0-9]|[０-９]){8}(-|‐|ー|－|―)([0-9]|[０-９])*$';
    case 'modalBirthDay':
    case 'modalBirthDayOld':
    case 'modalBirthDayNew':
      return '^(?!([2２][1235679１２３５６７９]|[1１][01345789０１３４５７８９])[0０][0０][0０][2２][2２][9９])(([12１２][0-9０-９]{3}([0０][1１]|[0０][3３]|[0０][5５]|[0０][7７]|[0０][8８]|[1１][0０]|[1１][2２])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][01０１]))|([12１２][0-9０-９]{3}([0０][4４]|[0０][6６]|[0０][9９]|[1１][1１])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][0０]))|([12１２][0-9０-９]{3}[0０][2２]([0０][1-9１-９]|[1１][0-9０-９]|[2２][0-8０-８]))|([12１２][0-9０-９]([02468０２４６８][048０４８]|[13579１３５７９][26２６])[0０][2２][2２][9９]))$';
    case 'modalKana':
      return '^([ァ-ン]|ー|－|―)+$';
    case 'modalKanaName':
      return '^([ぁ-ん]|ー|－|―)+$';
    default:
      return '';
  }
}

const isNormalFormatValue = (id='', value='') => {
  if (typeof value === 'boolean') return true;
  if (id === 'modalName') return true;
  if (id === 'modalEmail') return (value.length === 0) || EmailValidator.validate(value);
  if (value.length === 0) return true;
  const validatePattern = convertIdIntoValidatePattern(id);
  return Boolean(value.match(new RegExp(validatePattern)));
}

const isInputValue = (value='') => {
  if (typeof value === 'boolean') return value;
  const trimValue = value.trim();
  if (trimValue && trimValue.length > 0) return true;
  return false;
}

const UserInfoInput = ({ userInfo, queue, onCloseModal, onChangeInput, onSubmitModal, displayQueueCategory }) => {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [formInputList, setFormInputList] = useState({});
  const [formMessage, setFormMessage] = useState([]);
  const [errorMessageList, setErrorMessageList] = useState({});

  useEffect(() => {
    const { formKey, categorization, inputs, message } = userInfo;

    const formMessageCetegory = message[formKey];
    const translatedMessages = i18n.t(`modal.message.${formMessageCetegory}`);
    const arrayMessage = (translatedMessages instanceof Array) ? translatedMessages : [];
    const checkedArrayMsg = arrayMessage.map((message) => changeToCustomHTML(message));
    setFormMessage(checkedArrayMsg);

    const form = categorization[formKey];
    const inputList = form
      .reduce((prev, { input, required }) => {
        const id = inputs[input].id;
        return { ...prev, [id]: { value: null, valueCheck: false, required } };
      }, {});
    const errMesList = form
      .reduce((prev, { input }) => {
        const id = inputs[input].id;
        return { ...prev, [id]: '' };
      }, {});
    setFormInputList(inputList);
    setErrorMessageList(errMesList);
  }, []);

  const _checkChangeValidation = (formType) => (event={}) => {
    const { target={} } = event;
    const { id='', required=false } = target;
    const value = formType === 'checkbox' ? target.checked || false : target.value || '';

    const isInput = (required) ? isInputValue(value) : true;
    const isNormalFormat = isNormalFormatValue(id, value);
    const checkResult = isInput && isNormalFormat;
    if (checkResult) _changeErrorElement(id);
    if (formType === 'checkbox' && !value) _changeErrorElement(id, 'required');

    const newInputList = { ...formInputList, [id]: { value, valueCheck: checkResult, required }};
    _checkAllError(newInputList);
    setFormInputList(newInputList);
    onChangeInput(id, value);
  }

  const _checkBlurValidation = (formType) => (event={}) => {
    const { target={} } = event;
    const { id='', required=false } = target;
    const value = formType === 'checkbox' ? target.checked || false : target.value || '';

    const isInput = (required) ? isInputValue(value) : true;
    if (required && !isInput) _changeErrorElement(id, 'required');
    const isNormalFormat = isNormalFormatValue(id, value);
    if (isInput && !isNormalFormat) _changeErrorElement(id, 'format');

    _checkAllError(formInputList);
  }

  const _checkAllError = (inputList) => {
    const inputValues = Object.keys(inputList).map(key => inputList[key]);
    // require error check
    const requiredError = inputValues.find(({ value, required }) => (required && !value));
    if (requiredError) return setSubmitButtonDisabled(true);
    // validation check
    const allError = inputValues.find(({ valueCheck, value }) => (value && !valueCheck));
    if (allError) return setSubmitButtonDisabled(true);
    return setSubmitButtonDisabled(false);
  }

  const _changeErrorElement = (id='', error='') => {
    const errorMessage = i18n.t(`modal.errorMessage.${error}`);
    const checkedErrorMessage = errorMessage[id] || '';
    setErrorMessageList({ ...errorMessageList, [id]: checkedErrorMessage });
  }

  return (
    <div>
      <div style={{float: 'left'}}>
        <div className={'operatorMessage__grid'}>
          <div className={'operatorMessage__icon'}><img src={modalIcon} style={{width: '100%'}}/></div>
        </div>
      </div>
      <div className={'operatorMessage__balloon'} style={{fontSize: 'smaller',display: 'table-cell'}}>
        {formMessage.map((message,index) => (<CustomReactMarkdown key={index} children={message} />))}
      </div>

      <div>
        <UserInfoInputList userInfo={userInfo} onChange={_checkChangeValidation} onBlur={_checkBlurValidation} errMsgs={errorMessageList}/>
        {createWaitCountMessage(queue, displayQueueCategory)}
        <hr></hr>
        <div style={{float: 'right', paddingBottom: '20px'}}>
          <button
            type='button'
            id='modalCancelButton'
            className={'modalInput__button modalInput__cancel'}
            style={{marginRight: '7px'}}
            onClick={onCloseModal}
          >
            {i18n.t('cancelButton')}
          </button>
          <button
            type='button'
            id='modalSubmitButton'
            className={`modalInput__button modalInput__submit ${submitButtonDisabled ? 'modalInputButton__disabled' : ''}`}
            onClick={onSubmitModal}
            disabled={submitButtonDisabled}
          >
            {i18n.t('sendButton')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserInfoInput;

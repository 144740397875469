import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/NameActions"
import { settings } from "../config/settings"

class NameStore extends ReduceStore {

  getInitialState() {
    return settings.name
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return  {
          name: action.name
        }
      default:
        return state
    }
  }
}

export default new NameStore(Dispatcher)

import React from 'react';
import Modal from 'react-modal';
import i18n from "../i18n";
import ModalWindowActions from "../actions/ModalWindowActions";
import UserInfoActions from "../actions/UserInfoActions";

import UserInfoInput from "./UserInfoInput";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-40%',
    paddingBottom         : '0px',
    transform             : 'translate(-50%, -50%)',
    background            : '#e9eff2',
    maxHeight             : '100%',
  }
};
Modal.setAppElement('#root')

class ModalWindow extends React.Component {
  constructor(props) {
    super(props);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.afterCloseModal = this.afterCloseModal.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.submitModal = this.submitModal.bind(this);
  }

  _getInfo(isCancel=false) {
    const { userInfo={} } = this.props;
    const { modalName, modalTel, modalPostalCode, modalAddress,
            modalBirthDay, modalOrderNumber, modalEmail,
            modalFacilityName, modalLastName, modalFirstName,
            modalKana, modalKanaName, modalReservationNumber, modalInquiryContent,
            modalBirthDayOld, modalBirthDayNew, modalBirthDayChangeReason,
            modalConfirmChangeBirthDay, modalConfirmShareInfo
          } = userInfo;
    return {
      name: modalName,
      kana: modalKana,
      tel: modalTel,
      postalCode: modalPostalCode,
      address: modalAddress,
      birthDay: modalBirthDay,
      orderNumber: modalOrderNumber,
      email: modalEmail,
      facilityName: modalFacilityName,
      lastName: modalLastName,
      firstName: modalFirstName,
      kanaName: modalKanaName,
      reservationNumber: modalReservationNumber,
      inquiryContent: modalInquiryContent,
      isCancel: isCancel,
      birthDayOld: modalBirthDayOld,
      birthDayNew: modalBirthDayNew,
      birthDayChangeReason: modalBirthDayChangeReason,
      confirmChangeBirthDay: modalConfirmChangeBirthDay,
      confirmShareInfo: modalConfirmShareInfo
    }
  }
  _clearInfo() {
    const { userInfo={} } = this.props;
    const { orderNumber='' } = userInfo;
    UserInfoActions.set({
      modalName: '',
      modalTel: '',
      modalPostalCode: '',
      modalAddress: '',
      modalBirthDay: '',
      modalOrderNumber: orderNumber,
      modalEmail: '',
      modalFacilityName: '',
      modalLastName: '',
      modalFirstName: '',
      modalKana: '',
      modalKanaName: '',
      modalReservationNumber: '',
      modalInquiryContent: '',
      birthDayOld: '',
      birthDayNew: '',
      birthDayChangeReason: '',
      confirmChangeBirthDay: false,
      confirmShareInfo: false,
    })
  }
  _setScrollHeight() {
    const html = document.documentElement;
    const body = document.body;
    body.scrollTop = body.scrollHeight;
    html.scrollTop = html.scrollHeight;
  }

  afterOpenModal() {
    this._setScrollHeight();
  }
  afterCloseModal() {
    this._setScrollHeight();
  }

  closeModal = () => {
    const { submit={} } = this.props
    const info = this._getInfo(true);
    this._clearInfo();
    ModalWindowActions.close(submit.opponent, info, i18n.t('cancelOperatorMessage'));
  }

  submitModal = () => {
    const { submit={}, message={}, questionnaire={} } = this.props;
    const info = this._getInfo(false);
    this._clearInfo();
    ModalWindowActions.submit(submit.opponent, info, message.contents, questionnaire);
  }

  onChangeInput = (id='', value='') => {
    UserInfoActions.set({[id]: value});
  }

  render() {
    const { userInfo={}, modalWindow={}, queue, displayQueueCategory } = this.props;
    return (
      <Modal
        isOpen={modalWindow.isOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        onAfterClose={this.afterCloseModal}
        style={customStyles}
        overlayClassName={'modal__overlay'}
        bodyOpenClassName={'modal__body'}
        htmlOpenClassName={'modal__html'}>
        <UserInfoInput
          userInfo={userInfo}
          queue={queue}
          displayQueueCategory={displayQueueCategory}
          onCloseModal={this.closeModal}
          onChangeInput={this.onChangeInput}
          onSubmitModal={this.submitModal}
        />
      </Modal>
    );
  }
}

export default ModalWindow;
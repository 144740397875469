import Dispatcher from "../Dispatcher"

export const keys = {
  isOnline: Symbol("status-lamp:is-online"),
  isOffline: Symbol("status-lamp:is-offline"),
}

export default {
  isOnline(){
    Dispatcher.dispatch({
      type: keys.isOnline,
    })
  },
  isOffline(){
    Dispatcher.dispatch({
      type: keys.isOffline,
    })
  },
}
import Dispatcher from "../Dispatcher"

export const keys = {
  unload: Symbol("shop:unload"),
  loading: Symbol("shop:loading"),
  succeed: Symbol("shop:master:succeed"),
  failed: Symbol("shop:master:failed"),
}

export default {
  load(shopId='') {
    Dispatcher.dispatch({
      type: keys.loading,
      shopId,
    })
  },
  succeed(master={}) {
    Dispatcher.dispatch({
      type: keys.succeed,
      master,
    })
  },
  failed() {
    Dispatcher.dispatch({
      type: keys.failed,
    })
  },
}

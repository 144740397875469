import Dispatcher from "../Dispatcher"

export const keys = {
  initial: Symbol("status:initial"),
  show: Symbol("announcement:show"),
  close: Symbol("status:close"),
}

export default {
  show(title, msg){
    Dispatcher.dispatch({
      type: keys.show,
      title,
      msg
    })
  },
  close(text){
    Dispatcher.dispatch({
      type: keys.close,
    })
  },
  focus(){
    window.focus();
  }
}
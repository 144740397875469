import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ModalWindowActions"
import { settings } from "../config/settings"

class ModalWindowStore extends ReduceStore {

  getInitialState() {
    return settings.modalWindow
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.show:
      case keys.close:
      case keys.submit:
        state.isOpen = action.isOpen
        return {...state}

      default:
        return state
    }
  }
}

export default new ModalWindowStore(Dispatcher)

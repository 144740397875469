import Dispatcher from "../Dispatcher"
import SocketActions from "./SocketActions"
import InputActions from "./InputActions"
import MessageActions from "./MessageActions"
import SuggestionActions from "./SuggestionActions"
import { isSmartPhone } from "chat-common"
import i18n from "../i18n"

import trimBr from "trim-newlines"

export const keys = {
  changeOpponent: Symbol("submit:change-opponent"),
  disable: Symbol('submit:disable'),
  enable: Symbol('submit:enable'),
  submit: Symbol('submit:submit'),
}

export default {
  changeOpponent(opponent){
    Dispatcher.dispatch({
      type: keys.changeOpponent,
      opponent,
    })
  },
  disable(grayOut=false){
    Dispatcher.dispatch({
      type: keys.disable,
      disabled: true,
      grayOut,
    })
  },
  enable(){
    Dispatcher.dispatch({
      type: keys.enable,
      disabled: false,
      grayOut: false,
    })
  },
  submit(text = "", inputType, state, contents, questionnaire){
    if( state.disabled || text.length === 0 || !trimBr(text).trim() ) return
    Dispatcher.dispatch({
      type: keys.submit,
      ...state
    })
    InputActions.clear()
    if(!isSmartPhone(navigator.userAgent)){
      InputActions.focus()
    }
    SuggestionActions.clear()
    if(text === i18n.t('seeMore')){
      MessageActions.addUserMoreMessage(text)
    } else {
      MessageActions.addUserMessage(text)
    }

    const {opponent} = state;
    if(opponent.match(/reception/)){
      SocketActions.postMessage(opponent, text, inputType, contents, questionnaire);
      return;
    }
    SocketActions.postMessage(opponent, text, inputType, undefined, undefined);
    return;
  }
}
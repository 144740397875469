import Dispatcher from "../Dispatcher"

import trimBr from "trim-newlines"

export const keys = {
  initialize: Symbol("input:initialize"),
  changePlaceholder: Symbol("input:change-placeholder"),
  input: Symbol("input:input"),
  change: Symbol("input:change"),
  disable: Symbol("input:disable"),
  enable: Symbol("input:enable"),
  clear: Symbol("input:clear"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize,
    })
  },
  changePlaceholder(placeholder){
    Dispatcher.dispatch({
      type: keys.changePlaceholder,
      placeholder,
    })
  },
  input(value){
    if( value.length !== 0 && !trimBr(value).trim() ) return
    Dispatcher.dispatch({
      type: keys.input,
      value,
    })
  },
  focus(){
    if(!window.getSelection().toString()){
      document.getElementById('inputField').focus()
    }
  },
  change(value){
    Dispatcher.dispatch({
      type: keys.change,

    })
  },
  disable(){
    Dispatcher.dispatch({
      type: keys.disable,
      disabled: true,
    })
  },
  enable(){
    Dispatcher.dispatch({
      type: keys.enable,
      disabled: false,
    })
  },
  clear(){
    Dispatcher.dispatch({
      type: keys.clear,
    })
  }
}
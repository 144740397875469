import React from 'react'
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'

export default ({ children, className, linkTarget }) =>
  <ReactMarkdown
    children={children}
    className={className || ''}
    rehypePlugins={[rehypeRaw]}
    components={{
      a: ({ node, ...props }) => <a {...props} onClick={null} />
    }}
    linkTarget={linkTarget}
  />
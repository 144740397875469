import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/TitleActions"
import { settings } from "../config/settings"

class TitleStore extends ReduceStore {

  getInitialState() {
    return settings.title
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.change:
        return action

      default:
        return state
    }
  }
}

export default new TitleStore(Dispatcher)

import Dispatcher from "../Dispatcher"

export const keys = {
  square: Symbol("icon:square"),
  circle: Symbol("icon:circle"),
  set: Symbol("icon:set"),
  load: Symbol("icon:load"),
}

export default {
  square(){
    Dispatcher.dispatch({
      type: keys.square,
    })
  },
  circle(){
    Dispatcher.dispatch({
      type: keys.circle,
    })
  },
  set(logoSrc, bg='#fff'){
    Dispatcher.dispatch({
      type: keys.set,
      logoSrc,
      bg,
    })
  },
  load(){
    Dispatcher.dispatch({
      type: keys.load,
    })
  },
}
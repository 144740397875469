import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/AnnouncementActions"
import { settings } from "../config/settings"

class AnnouncementStore extends ReduceStore {

  getInitialState() {
    return settings.announcement
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.show:
      case keys.close:
        return (state.title !== action.title)?action:state;

      default:
        return state
    }
  }
}

export default new AnnouncementStore(Dispatcher)

import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/LAAvailabilityActions"
import { settings } from "../config/settings"

class LAAvailabilityStore extends ReduceStore {

  getInitialState() {
    return settings.laAvailability
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initial:
        return {
          ...state,
        }
      case keys.updated:
        return {
          ...state,
          ...action.availability,
        }
      default:
        return state
    }
  }
}

export default new LAAvailabilityStore(Dispatcher)

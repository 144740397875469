import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/SSOActions"

class SSOStore extends ReduceStore {

  getInitialState() {
    return ''
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.login:
        return action

      default:
        return state
    }
  }
}

export default new SSOStore(Dispatcher)

import React from 'react';
import i18n from "../i18n";
import { isSmartPhone } from "chat-common";

import UserInfoInputFieldTextarea from './UserInfoInputFieldTextarea';
import UserInfoInputFieldInput from './UserInfoInputFieldInput';
import UserInfoInputFieldSelect from  './UserInfoInputFieldSelect';
import UserInfoInputFieldCheckBox from './UserInfoInputFieldCheckBox';

export default ({ type='text', id, name, value, required=false, maxLength, isTextArea=false, style={}, onChange, onBlur, errMsg }) => {
  const format = `modal.form.${name}`
  const translatedName = (id && i18n.exists(`${format}.name`)) ? i18n.t(`${format}.name`) : ''
  const translatedPlaceholder = (id && i18n.exists(`${format}.placeholder`)) ? i18n.t(`${format}.placeholder`) : ''

  const requiredMark = (required) ? <span>*</span> : ''
  const fixedStyle = isSmartPhone(navigator.userAgent) ? style.sp : style.pc;
  const isError = Boolean(errMsg);

  const InputComponent = () => {
    const onChangeFunc = onChange(type);
    const onBlurFunc = onBlur(type);
    switch(type) {
      case 'text': return isTextArea ?
          (<UserInfoInputFieldTextarea id={id} value={value} style={fixedStyle} required={required} isSpace={!isError}
            placeholder={translatedPlaceholder} maxLength={maxLength} onChange={onChangeFunc} onBlur={onBlurFunc}/>) :
          (<UserInfoInputFieldInput id={id} value={value} style={fixedStyle} type={type} required={required} isSpace={!isError}
            placeholder={translatedPlaceholder} maxLength={maxLength} onChange={onChangeFunc}onBlur={onBlurFunc}/>);
      case 'tel':
      case 'email': return (
        <UserInfoInputFieldInput id={id} value={value} style={fixedStyle} type={type} required={required} isSpace={!isError}
          placeholder={translatedPlaceholder} maxLength={maxLength} onChange={onChangeFunc}onBlur={onBlurFunc}/>
      );
      case 'select': return (
        <UserInfoInputFieldSelect id={id} style={fixedStyle} required={required} onChange={onChangeFunc}
          onBlur={onBlurFunc} selectItemsKey={name} isSpace={!isError}/>
      );
      case 'checkbox': return (
        <UserInfoInputFieldCheckBox id={id} style={fixedStyle} required={required} onChange={onChangeFunc}
          onBlur={onBlurFunc} checkContent={i18n.t(`modal.checkBoxContent.${name}`)} isSpace={!isError}/>
      );
      default: return null;
    }
  }

  return (
    <div className='modalInput'>
      <label>{translatedName}{requiredMark}</label>
      {InputComponent()}
      <span className={'errorMessage'} id={`${id}Error`} hidden={!isError}>{errMsg}</span>
    </div>
  );
}

import { settings } from "../config/settings";
const { calcWaitCountType } = settings;
const hyphen = " − "

export const calcWaitCount = (queue = null, category = '', sid = '') => {
  if(!queue) return hyphen
  const filteredQueue = queue.filter(q => q.includes(':')) // to remove invalid format data
  switch (calcWaitCountType) {
    case 'ALL': {
      if (!sid) return filteredQueue.length;
      for(const [index, q] of Object.entries(filteredQueue)){
        const [_sid, _] = q.split(":")
        if(_sid === sid) return index
      }
      return hyphen
    }
    case 'CATEGORY': {
      if (!category) return hyphen;
      const categoryQueue = filteredQueue.filter(q => q.endsWith(category))
      if (!sid) return categoryQueue.length;
      const queueIndex = categoryQueue.indexOf(`${sid}:${category}`)
      if (queueIndex === -1) return hyphen;
      return queueIndex
    }
    default: return hyphen;
  }
}
import Dispatcher from "../Dispatcher"

export const keys = {
  start: Symbol("conversation:start"),
  stop: Symbol("conversation:stop"),
}

export default {
  start() {
    Dispatcher.dispatch({
      type: keys.start
    })
  },
  stop() {
    Dispatcher.dispatch({
      type: keys.stop
    })
  },
}

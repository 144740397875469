import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/SubmitActions"
import { settings } from "../config/settings"

class SubmitStore extends ReduceStore {

  getInitialState() {
    return settings.submit
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.changeOpponent:
        return {...state, opponent: action.opponent}
      case keys.disable:
      case keys.enable:
        return {
          ...state,
          disabled: action.disabled,
          grayOut: action.grayOut,
        }
      case keys.submit:
        return {...action}
      
      default:
        return state
    }
  }
}

export default new SubmitStore(Dispatcher)

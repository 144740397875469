import Dispatcher from "../Dispatcher"

export const keys = {
  setName: Symbol("typing:set-name"),
  agentNotTyping: Symbol("typing:agent-not-typing"),
  agentTyping: Symbol("typing:agent-typing"),
}

export default {
  setName(name){
    Dispatcher.dispatch({
      type: keys.setName,
      name
    })
  },
  agentTyping(){
    Dispatcher.dispatch({
      type: keys.agentTyping,
      isDisplay: true
    })
  },
  agentNotTyping(){
    Dispatcher.dispatch({
      type: keys.agentNotTyping,
      isDisplay: false
    })
  },
}
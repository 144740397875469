import React, { Component } from "react"
import { Container } from "flux/utils"

import Icon from '../components/Icon'
import Name from "../components/Name";
import Personel from '../components/Personel'
import StatusLamp from '../components/StatusLamp'

import IconStore from '../stores/IconStore'
import NameStore from '../stores/NameStore'
import PersonelStore from '../stores/PersonelStore'
import StatusLampStore from '../stores/StatusLampStore'

class ProfileContainer extends Component {
  static getStores() {
    return [
      IconStore,
      StatusLampStore,
      PersonelStore,
      NameStore,
    ]
  }
  static calculateState(prevState) {
    return {
      icon: IconStore.getState(),
      statusLamp: StatusLampStore.getState(),
      personel: PersonelStore.getState(),
      name: NameStore.getState(),
    }
  }

  render() {
    return (
      <div className="profile profile--bot">
        <Icon {...this.state.icon} />
        <StatusLamp {...this.state.statusLamp} />
        <Personel {...this.state.personel} />
        <Name {...this.state.name} />
      </div>
    )
  }
}

export default Container.create(ProfileContainer)
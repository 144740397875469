import React from "react"
import linkify from "html-linkify"
import moment from "moment"
import i18n from "../i18n"

export default ({text, now, balloonId}) => {
  let message = ""
  if(text.includes("<img ") || text.includes("<a ")){
    let wrapDiv = document.createElement('div')
    wrapDiv.innerHTML = text
    let links = wrapDiv.getElementsByTagName("a")
    if(links){
      for ( let i=0; i<links.length; i++){
        links[i] && links[i].setAttribute('target','_blank')
      }
    }
    message = { __html: wrapDiv.innerHTML }
  } else {
    message={ __html: linkify( text.replace(/\n/g,"<br/>") , { escape:false,attributes:{target:"_blank"}} )}
  }
  let time = moment(now).format("HH:mm")
  const wordNormalClass = (i18n.language === 'en') ? 'messageWordNormal' : ''
  return (
  <div className={`operatorMessage u-clearfix ${balloonId}`}>
    <div className="operatorMessage__inner">
      <div className="operatorMessage__grid">
        <div className="operatorMessage__icon operatorMessage__icon--operator"></div>
      </div>
      <div className="operatorMessage__grid operatorMessage__grid--balloon">
        <div className={`operatorMessage__balloon ${wordNormalClass}`}>
          <div dangerouslySetInnerHTML={message}/>
        </div>
      </div>
      <div className="operatorMessage__grid operatorMessage__grid--time">
        <div className="operatorMessage__time">{time}</div>
      </div>
    </div>
  </div>
  )
}

import React from "react"
import OrderCarouselButton from "./OrderCarouselButton"
import OrderCarouselImage from "./OrderCarouselImage"
import CancelledOrderCarouselTable from "./CancelledOrderCarouselTable";
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({ images, title, subtitle, text, titleInImage, textInImage, buttons, disabled, clickButton, clickLink }) => {
  const isCancelledOrder = images.find(image => 
    image.alt.startsWith("cancelled_order___")
  )
  const styleBackground = isCancelledOrder ? { backgroundColor: "#dadee0" } : {};
  return (
  <li className="orderCarousel__attachment" style={styleBackground} >
    {(isCancelledOrder)
      ?<CancelledOrderCarouselTable images={images} disabled={disabled} clickButton={clickButton} />
      :<OrderCarouselImage images={images} title={titleInImage} text={textInImage} disabled={disabled} clickButton={clickButton} />
    }
    <div>
      {(title)?<h1 className="orderCarousel__title"><CustomReactMarkdown children={title} /></h1>:null}
      {(subtitle)?<h2 className="orderCarousel__subtitle"><CustomReactMarkdown children={subtitle} /></h2>:null}
      {(text)?<CustomReactMarkdown children={text} className="orderCarousel__content" />:null}
      <div className="orderCarousel__buttons_area">
        <ul className="orderCarousel__buttons">
          {buttons.map((button, index) => <OrderCarouselButton clickButton={clickButton} button={button} key={index} clickLink={clickLink} disabled={disabled}/>)}
        </ul>
      </div>
    </div>
  </li>
)}

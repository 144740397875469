import { io } from "socket.io-client"
import moment from "moment-timezone"
import i18n from "./i18n";
import { isProduction } from 'chat-common'

import AnnouncementActions from "./actions/AnnouncementActions"
import StatusActions from "./actions/StatusActions"
import ChatSettingActions from './actions/ChatSettingActions'
import ConnectionActions from "./actions/ConnectionActions"
import ConversationActions from "./actions/ConversationActions"
import DropAreaActions from "./actions/DropAreaActions"
import DisconnectActions from "./actions/DisconnectActions"
import LAAvailabilityActions from "./actions/LAAvailabilityActions"
import MessageActions from './actions/MessageActions'
import MenuActions from './actions/MenuActions'
import SubmitActions from "./actions/SubmitActions";
import SuggestionActions from './actions/SuggestionActions'
import TypingActions from "./actions/TypingActions";
import IconActions from "./actions/IconActions"
import StatusLampActions from './actions/StatusLampActions'
import NameActions from './actions/NameActions'
import InputActions from "./actions/InputActions";
import PersonelActions from "./actions/PersonelActions";
import NotificationActions from "./actions/NotificationActions";
import BotErrorActions from "./actions/BotErrorActions";
import QuestionnaireActions from "./actions/QuestionnaireActions";
import UploadActions from "./actions/UploadActions";
import ModalActions from "./actions/ModalWindowActions";
import UserInfoActions from "./actions/UserInfoActions";
import ShopActions from "./actions/ShopActions";
import TitleActions from "./actions/TitleActions";
import BotMaintenanceActions from "./actions/BotMaintenanceActions";
import QueueActions from "./actions/QueueActions"
import DisplayQueueCategoryActions from './actions/DisplayQueueCategoryActions';
import SalesforceActions from './actions/SalesforceActions';
import { settings } from "./config/settings";

const path = (!process.env.REACT_APP_IS_LOCAL) ?
  (service => {
    switch(service){
      case 'direct':
        return '/direct/socket.io/';
      case 'fashion':
        return '/fashion/socket.io/';
      default:
        return '';
    }
  })(process.env.REACT_APP_SERVICE) : '';
// const url = ( env => {
//   switch (env) {
//     case 'STAGING':
//       return { 'merchant' : 'https://svc-80-ichiba-cc-chatbot-client-stg.jpe1-caas1-prod1.caas.jpe1a.r-local.net'}
//     default:
//       return {}
//   }
// })(process.env.REACT_APP_ENV)[process.env.REACT_APP_SERVICE]
const socket = io('', { transports: ["websocket"], path })

socket.on('request-queue', (count) => {
  QueueActions.set(count)
})
socket.on('change-liveagent-status', (availability) => {
  LAAvailabilityActions.updated(availability)
})
socket.on("rlogin:failed", ()=> {
  window.location='https://mainmenu.rms.rakuten.co.jp/?act=app_login_error'
})
socket.on("cookie:set",(tmpid) => {
  const expires = `${moment.tz('GMT').add(3,'M').format('ddd, d-MMM-YYYY hh:mm:ss')} GMT`
  document.cookie=`tmpid=${tmpid}; expires=${expires}; secure`
})
socket.on('login:suceeded', ()=>{
  //......//
})
socket.on('questionnaire:set', (item)=>{
  QuestionnaireActions.set(item)
})
socket.on('userInfo:set', (item)=>{
  UserInfoActions.set(item)
})
socket.on('connect', () => {
  InputActions.enable()
  SubmitActions.enable()
  StatusActions.isCompleted('statusMessage.connect');
  StatusLampActions.isOnline();
  ConnectionActions.connect();
})
// (re)connection failure or error after a successful connection
socket.io.on('error', () => {
  InputActions.disable()
  SubmitActions.disable()
  StatusActions.isError('statusMessage.connectError')
  StatusLampActions.isOffline();
  ConnectionActions.error();
})
// connection failure
socket.on('connect_error', () => {
  InputActions.disable()
  SubmitActions.disable()
  StatusActions.isError('statusMessage.connectError')
  StatusLampActions.isOffline();
  ConnectionActions.error();
})
socket.on('disconnect', () => {
  DropAreaActions.hide()
  DropAreaActions.disable()
  DisconnectActions.disable()
  InputActions.disable()
  SubmitActions.disable()
  UploadActions.disable()
  UploadActions.hide();
  StatusLampActions.isOffline();
  StatusActions.isCompleted('statusMessage.disconnect')
})
socket.io.on('reconnect', (data) => {
  InputActions.disable()
  SubmitActions.disable()
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  StatusActions.isProcessing('statusMessage.reconnect');
  ConnectionActions.reconnect(data);
})
// reconnect_attemptとrecconectingはreconnect_attemptに統合
socket.io.on('reconnect_attempt', (data) => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  StatusActions.isProcessing('statusMessage.reconnecting', data);
  ConnectionActions.reconnecting(data);
})
socket.io.on('reconnect_error', () => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  StatusActions.isError('statusMessage.reconnectError');
  ConnectionActions.reconnectError();
})
socket.io.on('reconnect_failed', () => {
  InputActions.disable();
  SubmitActions.disable();
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusLampActions.isOffline();
  StatusActions.isError('statusMessage.reconnectFailed');
  ConnectionActions.reconnectFailed();
})
socket.on('bot:maintenance', ()=>{
  BotErrorActions.show();
  BotMaintenanceActions.change(true);
})
socket.on('operator:maintenance', ({activities})=>{
  SubmitActions.changeOpponent('bot');
  MenuActions.enable();
  MessageActions.addBotMessage(activities[0]);
  InputActions.initialize()
  InputActions.enable();
  InputActions.focus();
})
socket.on('chat:channel-control', (displayChannels) => {
  MenuActions.initialize(displayChannels,true)
})
socket.on("chat:set-chat-session-id", (chatSessionId) => {ChatSettingActions.setSessionId(chatSessionId)})
socket.on("chat:announcement", (announcementEl) => {AnnouncementActions.show('announcementTitle',announcementEl)})
socket.on('chat:get-shop:success', (shopMaster={}) => {
  ShopActions.succeed(shopMaster);
  const { shopName='', url='' } = shopMaster;
  const targetLogo = `https://thumbnail.image.rakuten.co.jp/@0_mall/${url}/logo/logo1.jpg`;
  IconActions.set(targetLogo);
  NameActions.set(`${shopName}${i18n.t('profileName.shop')}`);
  TitleActions.change(`${i18n.t('title')}${shopName}`);
  MessageActions.setBotIcon(targetLogo);
})
socket.on('chat:get-shop:failure', () => {
  ShopActions.failed();
  IconActions.set('', '#ddd');
  NameActions.set('profileName.shopInfoFailed');
  MessageActions.setBotIcon('');
})

socket.on('reception:session-timeout', ()=>{
  setTimeout(window.location.reload(),1000)
});
socket.on('operator:session-timeout', () => {
  DropAreaActions.hide()
  DropAreaActions.disable()
  StatusActions.initialize()
  setTimeout(()=>{  StatusActions.isCompleted('statusMessage.timeoutOperator')})
  StatusLampActions.isOffline()
  PersonelActions.set('')
  NameActions.set('statusMessage.disconnect')
  InputActions.clear()
  InputActions.disable()
  InputActions.changePlaceholder('placeholder.disconnect')
  SubmitActions.disable()
  UploadActions.disable()
  UploadActions.hide();
  socket.close();
})
socket.on("bot:get-message:success", ({activities}) => {
  MessageActions.addBotMessage(activities[0])
})
socket.on("bot:input-disable",(flag)=>{
  if(flag){
    InputActions.disable()
    InputActions.changePlaceholder('placeholder.inputDisable')
  } else {
    InputActions.initialize()
  }
})
socket.on("reception:select-category", ({activities}) => {
  SubmitActions.changeOpponent('reception-category')
  MessageActions.addBotMessage(activities[0])
  MenuActions.disable()
  InputActions.changePlaceholder('placeholder.selectCategory')
  InputActions.disable();
})
socket.on("reception:input-email", ({activities}) => {
  SubmitActions.changeOpponent('reception-input-email')
  MenuActions.disable()
  MessageActions.addBotMessage(activities[0])
  InputActions.changePlaceholder('placeholder.inputEmail')
  InputActions.enable();
  InputActions.focus();
})
socket.on("reception:input-detail", ({formKey}) => {
  SubmitActions.changeOpponent('reception-input-detail')
  SubmitActions.disable(true);
  InputActions.changePlaceholder('')
  InputActions.disable();
  DisplayQueueCategoryActions.set(formKey);
  UserInfoActions.set({formKey});
  ModalActions.show();
})
socket.on("reception:send-inquiryForm", ({activities}) => {
  MessageActions.addBotMessage(activities[0])
})
socket.on("reception:quit", ({activities}) => { 
  const firstActivities = (activities && activities.length > 0) ? activities[0] : undefined
  if (firstActivities) MessageActions.addBotMessage(activities[0])
  SubmitActions.changeOpponent('bot')
  MenuActions.enable()
  InputActions.initialize()
  InputActions.enable();
  InputActions.focus();
})

socket.on("operator:wait-information-changed", ()=>{
  TypingActions.setName('profileName.connectingOperator')
  StatusActions.isProcessing("statusMessage.connectingOperator")
  SubmitActions.changeOpponent('operator')
  InputActions.changePlaceholder('placeholder.connectingOperator')
  InputActions.disable()
  IconActions.set('img/operator_icon_pc.png')
  DisconnectActions.show()
  DisconnectActions.enable()
  setTimeout(()=>StatusLampActions.isOffline())
  PersonelActions.set('')
  NameActions.set('profileName.connectingOperator')
  MenuActions.hide()
})
socket.on('operator:reconnect', ()=>{
  StatusLampActions.isOnline()
  InputActions.enable()
  SubmitActions.enable()
  DisconnectActions.enable()
  if(settings.socketAction.upload){
    UploadActions.show();
    UploadActions.enable();
    DropAreaActions.enable();
    DropAreaActions.hide();
  }
})
socket.on("operator:agent-not-typing", () => {TypingActions.agentNotTyping()})
socket.on("operator:agent-typing", () => {TypingActions.agentTyping()})
socket.on("operator:participant-added", (message)=>{
  const { name } = message
  if(settings.socketAction.upload){
    UploadActions.show();
    UploadActions.enable();
    DropAreaActions.enable();
    DropAreaActions.hide();
  }
  TypingActions.setName(name)
  StatusActions.isCompleted('statusMessage.operator')
  SubmitActions.changeOpponent('operator')
  IconActions.set('img/operator_icon_pc.png')
  StatusLampActions.isOnline()
  if(name instanceof Array){
    NameActions.set(name)
  } else {
    PersonelActions.set('profileName.operator')
    NameActions.set([name])
  }
  InputActions.changePlaceholder('placeholder.operator')
  InputActions.enable()
  MenuActions.hide()
  DisconnectActions.show()
})
socket.on("operator:message-posted", (message)=>{
  const { text } = message
  MessageActions.addOperatorMessage(text)
  NotificationActions.show(text)
  socket.emit('operator:message-posted:success', text)
})
socket.on('bot:set-suggestion', (defaultSuggestions) => { SuggestionActions.setDefaultItem(defaultSuggestions) })
socket.on('bot:get-suggestion:success', (suggestions) => { SuggestionActions.show(suggestions) })
socket.on("operator:concluded", ({message}) => {
  StatusActions.initialize()
  setTimeout(()=>{StatusActions.isCompleted(message)})
  SubmitActions.disable()
  DisconnectActions.disable()
  DisconnectActions.hide()
  InputActions.clear()
  InputActions.changePlaceholder('placeholder.disconnect')
  InputActions.disable()
  StatusLampActions.isOffline()
  PersonelActions.set('')
  NameActions.set('profileName.disconnect')
  UploadActions.hide();
  UploadActions.disable();
  socket.close();
})
socket.on("operator:chat-request-fail", ({message}) => {
  StatusActions.initialize()
  setTimeout(()=>{StatusActions.isCompleted(message)})
  SubmitActions.disable()
  DisconnectActions.disable()
  DisconnectActions.hide()
  InputActions.clear()
  InputActions.changePlaceholder('placeholder.disconnect')
  InputActions.disable()
  StatusLampActions.isOffline()
  PersonelActions.set('')
  NameActions.set('profileName.disconnect')
  UploadActions.hide();
  UploadActions.disable();
  socket.close();
})

socket.on("operator:upload-file:success",(id, fileDetails)=>{
  if(process.env.REACT_APP_SERVICE==='merchant'){
    const {object_name, fileUrl} = fileDetails;
    MessageActions.addUserMessage('ファイル送信: '+object_name, fileUrl, false, true, id)
  }
  MessageActions.completedUpload(id)
})
socket.on('operator:upload-file:failure', (id) => {
  MessageActions.completedUpload(id);
})
socket.on('operator:post-salesforce-id', (id) => {
  SalesforceActions.setId(id);
})

/*
socket.on('window-open', ()=>{
  console.log('window-open')
  window.opener.postMessage('test','https://chat.ichiba.faq.rakuten.co.jp/')
})
*/


export default {
  startBot(opponent, conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityInfo) {
    socket.emit(`${opponent}:start`,  { conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityInfo, } )
  },
  postMessage(opponent, text, inputType, contents=[], questionnaire={}) {
    const isPro = isProduction(window.location.hostname)
    socket.emit(`${opponent}:post-message`, { text, inputType, screenSize: `${window.screen.availWidth}x${window.screen.availHeight}`, messageHistory: contents, questionnaire, isProduction: isPro, })
  },
  postDetail(opponent, info, contents=[], questionnaire={}) {
    socket.emit(`${opponent}:post-detail`, { info, screenSize: `${window.screen.availWidth}x${window.screen.availHeight}`, messageHistory: contents, questionnaire })
  },
  getSuggestion(text) {
    socket.emit("bot:get-suggestion", { text })
  },
  report({timing="",place="",text="",action=""}) {
    socket.emit("bot:user-action-report", {timing,place,content: text,action})
  },
  sendUserTyping(){
    socket.emit("operator:user-typing")
  },
  sendUserNotTyping(){
    socket.emit("operator:user-not-typing")
  },
  sendUserTextAsSneakPeak(text){
    socket.emit("operator:sneak-peak", text)
  },
  uploadFile(filename, filetype, filesize, data_uri, id) {
    socket.emit("operator:upload-file", { filename, filetype, filesize, data_uri, id})
  },
  uploadFileSizeOver(id) {
    socket.emit("operator:upload-file-size-over",id)
  },
  checkChatDisconnect() {
    socket.emit("operator:disconnect")
  },
  linkOpened({targetLink}){
    socket.emit("bot:user-link-opened", {targetLink})
  },
  close() {
    socket.close()
  },
}

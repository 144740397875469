import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/IconActions"
import { settings } from "../config/settings"

class IconStore extends ReduceStore {

  getInitialState() {
    return settings.icon
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.square:
        return {
          ...state,
          radius: '20%'
        }
      case keys.circle:
        return {
          ...state,
          radius: '50%'
        }
      case keys.load:
        return {
          ...state,
          logoClassName: 'loading',
          logoSrc: '/img/circlepic.png',
          bg: '#ddd'
        }
      case keys.set:
        return {
          ...state,
          logoClassName: '',
          logoSrc: action.logoSrc,
          bg: action.bg
        }
      default:
        return state
    }
  }
}

export default new IconStore(Dispatcher)

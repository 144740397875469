import Dispatcher from "../Dispatcher"

export const keys = {
  setIcon: Symbol("notification:set-icon"),
  setLang: Symbol("notification:set-lang"),
  show: Symbol("notification:show")
}

export default {
  setIcon(icon){
    Dispatcher.dispatch({
      type: keys.setIcon,
      icon
    })
  },
  setLang(lang){
    Dispatcher.dispatch({
      type: keys.setLang,
      lang
    })
  },
  show( title, body, data=undefined ) {
    Dispatcher.dispatch({
      type: keys.show,
      tag: Date.now(),
      title,
      body,
      data
    })
  },
}

import Dispatcher from "../Dispatcher"

export const keys = {
  login: Symbol("sso:login")
}

export default {
  login(id){
    Dispatcher.dispatch({
      type: keys.login,
      id
    })
  }
}
import React from "react"

import MultipleChoiceQuestion from "./MultipleChoiceQuestion"
import TwoChoiceQuestion from "./TwoChoiceQuestion"
import CategoryChoice from "./CategoryChoice";

export default ({buttons=[], disabled, clickButton, clickLink, availability}) => {
  if (buttons.length > 0){
    const linkButton = buttons.find(button => (button.type === 'openUrl') )
    const over7char = buttons.find(button => (button.title.length >= 7))
    const areTwoButtons = (buttons.length === 2)
    const selectCategory = buttons.find(button => (button.category))
    return (selectCategory)
    ? (<CategoryChoice buttons={buttons} clickButton={clickButton} clickLink={clickLink} disabled={disabled} availability={availability}/>)
    : (areTwoButtons && !linkButton && !over7char)
      ? (<TwoChoiceQuestion buttons={buttons} clickButton={clickButton} disabled={disabled} />)
      : (<MultipleChoiceQuestion buttons={buttons} clickButton={clickButton} clickLink={clickLink} disabled={disabled} />)
  } else {
    return <div></div>
  }
}
import React from "react"

export default ({ images, disabled, clickButton }) => {
  const clickAction = (event) => {
    if(!disabled)clickButton(event)
  }
  const cancelMessage = "このご注文はキャンセル済みです";
  const orderNumber = images[0].alt.replace(/cancelled_order___/g, "");
  const value = images[0].tap.value;
  const type = images[0].tap.type;

  return (
    <div className="cancelledOrderTable cursor_default"  onClick={clickAction} value={value} type={type} disabled={disabled} >
      <div className="cancelledOrderTableRow cancelledOrderTableRowMessage" value={value} type={type} disabled={disabled} >
        <div className="cancelledOrderTableColumn cancelledOrderTableColumnMessage" value={value} type={type} disabled={disabled} >
          {cancelMessage}
        </div>
      </div>
      <div className="cancelledOrderTableRow cancelledOrderTableRowOrderNumber" value={value} type={type} disabled={disabled} >
        <div className="cancelledOrderTableColumn cancelledOrderTableColumnOrderNumber" value={value} type={type} disabled={disabled} >
          {'受注番号：'}<br />
          {orderNumber}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import i18n from "../i18n";

export default ({ id, required, style, onChange, onBlur, selectItemsKey, isSpace }) => {
  const selectItems = i18n.t(`modal.selectItems.${selectItemsKey}`);
  return (
    <select
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      required={required}
      className={`modalInput__selectField ${isSpace ? 'modalInput__space' : ''}`}
    >
      <option value="" key={`key-${id}-init`} hidden></option>
      {selectItems.map((item, index) => (<option value={item} key={`key-${id}-${index}`}>{item}</option>))}
    </select>
  );
}
import React from "react"
import SquareBtnFilledArrow from "./SquareBtnFilledArrow"
import CustomReactMarkdown from "./CustomReactMarkdown"

export default ({ button, disabled, clickButton, clickLink }) => {
  switch (button.type) {
    case "imBack":
      return (<li className="orderCarousel__button">
        <button className="radiusBtn" onClick={clickButton} value={button.value} disabled={disabled}>
          <CustomReactMarkdown children={button.title} />
        </button>
      </li>)
    case "openUrl":
      return (<li className="multipleChoiceQuestion__item">
          <SquareBtnFilledArrow value={button.value} text={button.title} clickLink={clickLink} />
    </li>)
    default:
      return (<li className="orderCarousel__button">
        <button  className="radiusBtn" onClick={clickButton} value={button.value} >
          <CustomReactMarkdown children={button.title} />
        </button>
      </li>)
  }
}

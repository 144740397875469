import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/BotMaintenanceActions"
import { settings } from "../config/settings"

class BotMaintenanceStore extends ReduceStore {

  getInitialState() {
    return settings.botMaintenance
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.change:
        return action
      default:
        return state
    }
  }
}

export default new BotMaintenanceStore(Dispatcher)

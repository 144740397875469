import 'babel-polyfill';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import App from './containers/App';
import Auth from './containers/Auth';
import './index.css';
import './i18n';
import 'url-search-params-polyfill';
import axios from 'axios'
import Cookies from 'js-cookie';

function shopMerchant() {
  axios.get('/login')
    .then(res => {
      const { shopId, id } = res.data
      if (shopId && id) {
        document.getElementById('ratShopId').value = shopId
        ReactDOM.render(
          <BrowserRouter>
            <Auth id={id}>
              <div className="router">
                <Routes>
                  <Route path="/" element={<App />} />
                  <Route path="/:conversationCase" element={<App />} />
                </Routes>
              </div>
            </Auth>
          </BrowserRouter>,
          document.getElementById('root')
        );
      } else {
        window.location.assign('https://mainmenu.rms.rakuten.co.jp/?act=app_login_error')
      }
    }).catch(e => {
      window.location.assign('https://mainmenu.rms.rakuten.co.jp/?act=app_login_error')
    })
  return
}

function shopMerchantInternal() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/:conversationCase" element={<App />} />
        </Routes>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

function showFirstParty() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Routes>
          <Route path="/shopId/:shopId" element={<App />}/>
          <Route path="/orderNumber/:orderNumber" element={<App />}/>
          <Route path="/shopId/:shopId/:conversationCase" element={<App />}/>
          <Route path="/orderNumber/:orderNumber/:conversationCase" element={<App />}/>
          <Route path="/shopId/:shopId/health_checker/:healthChecker" element={<App />}/>
          <Route path="/orderNumber/:orderNumber/health_checker/:healthChecker" element={<App />}/>
        </Routes>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

function showDefault() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/health_checker/:healthChecker" element={<App />} />
          <Route path="/health_checker/:healthChecker/:ichiba_chat_referrer_id" element={<App />} />
          <Route path="/health_checker/:healthChecker/:ichiba_chat_referrer_id/:conversationCase" element={<App />} />
          <Route path="/rnt_chat_ref/:ichiba_chat_referrer_id" element={<App />} />
          <Route path="/rnt_chat_ref/:ichiba_chat_referrer_id/:conversationCase" element={<App />} />
          <Route path="/:conversationCase" element={<App />} />
        </Routes>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

const CHECK_STATUS_OK = 'CHECK_STATUS_OK'
const CHECK_STATUS_VERYFY = 'CHECK_STATUS_VERYFY'
const checkSFAuthList = [
  'hotel-chat.stg.travel-sd.faq.rakuten.net',// stg sf経由
  'travel-sd-chatbot-client-service-sb-80-travel-sd-chatbot-prd.jpe1-caas1-prod1.caas.jpe1a.r-local.net', // prod standby
  'hotel-chat.travel-sd.faq.rakuten.net'// prod
]

function TravelSD() {
  const [searchParams] = useSearchParams();
  const [checkStatus, setCheckStatus] = useState(null)
  const [spn, setSpn] = useState(null)
  const [Zoning, setZoning] = useState(null)
  const paramSpn = searchParams.get('spn') || ''
  const paramZoning = searchParams.get('Zoning') || ''
  const paramKey = searchParams.get('key') || ''

  useEffect(() => {
    if (!checkSFAuthList.includes(window.location.hostname)) {
      setCheckStatus(CHECK_STATUS_OK)
      setSpn(paramSpn)
      setZoning(paramZoning)
      return
    }

    if (!checkStatus) {
      setCheckStatus(CHECK_STATUS_VERYFY)

      axios.get('/verify', { params: { spn: paramSpn, Zoning: paramZoning, key: paramKey } })
        .then(value => {
          const { spn: verifiedSpn, Zoning: verifiedZoning } = value.data || {}
          if (!verifiedSpn || !verifiedZoning) {
            window.location.assign('https://web.travel.rakuten.co.jp/MY_KANRI/')
            return
          }
          setCheckStatus(CHECK_STATUS_OK)
          setSpn(verifiedSpn)
          setZoning(verifiedZoning)
        })
        .catch(_ => {
          window.location.assign('https://web.travel.rakuten.co.jp/MY_KANRI/')
        })
    }
  }, [checkStatus, paramKey, paramSpn, paramZoning])

  if (checkStatus !== CHECK_STATUS_OK) return (
    <p>認証中です...</p>
  )

  const appElem = <App spn={spn} Zoning={Zoning} />
  return (
    <Routes>
      <Route path="/" element={appElem} />
      <Route path="/health_checker/:healthChecker" element={appElem} />
      <Route path="/health_checker/:healthChecker/:ichiba_chat_referrer_id" element={appElem} />
      <Route path="/health_checker/:healthChecker/:ichiba_chat_referrer_id/:conversationCase" element={appElem} />
      <Route path="/rnt_chat_ref/:ichiba_chat_referrer_id" element={appElem} />
      <Route path="/rnt_chat_ref/:ichiba_chat_referrer_id/:conversationCase" element={appElem} />
      <Route path="/:conversationCase" element={appElem} />
    </Routes>
  )
}

function showTravelSD() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <TravelSD />
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

switch (process.env.REACT_APP_SERVICE) {
  case 'merchant':
    shopMerchant();
    break
  case 'merchant-internal':
    shopMerchantInternal();
    break
  case 'direct':
  case 'fashion':
    showFirstParty();
    break;
  case 'travel-sd':
    showTravelSD();
    break
  default:
    showDefault();
    break
};

import Dispatcher from "../Dispatcher"
import SubmitActions from "./SubmitActions";

export const keys = {
  isSP: Symbol("message:is-sp"),
  setBotIcon: Symbol("message:set-bot-icon"),
  completedUpload: Symbol('message:completed-upload'),
  addUserMessage: Symbol("message:add-user-message"),
  addUserMoreMessage: Symbol("message:add-user-more-message"),
  addUserExpandMessage: Symbol("message:add-user-expand-message"),
  addBotMessage: Symbol("message:add-bot-message"),
  addOperatorMessage: Symbol("message:add-operator-message")
}

export default {
  isSP(isSP){
    Dispatcher.dispatch({
      type: keys.isSP,
      device: (isSP)?'sp':'pc',
    })
  },
  setBotIcon(botIcon=''){
    Dispatcher.dispatch({
      type: keys.setBotIcon,
      botIcon,
    })
  },
  completedUpload(id){
    Dispatcher.dispatch({
      type: keys.completedUpload,
      id,
    })
  },
  addUserMessage(text, fileimg=undefined, isSending=false, isFile=false, id=undefined){
    Dispatcher.dispatch({
      type: keys.addUserMessage,
      content: {from:'user', now: new Date(), text, fileimg, isSending, isFile, id}
    })
  },
  addUserMoreMessage(text){
    Dispatcher.dispatch({
      type: keys.addUserMoreMessage,
      content: {from:'user', now: new Date(), text}
    })
  },
  addUserExpandMessage(text) {
    Dispatcher.dispatch({
      type: keys.addUserExpandMessage,
      content: { from: 'user', now: new Date(), text, isSending: false, isFile: false }
    })
  },
  addBotMessage(activity){
    SubmitActions.enable()
    Dispatcher.dispatch({
      type: keys.addBotMessage,
      content: {...activity, from:'bot', now: new Date()}
    })
  },
  addBotExpandMessage(answer){
    const texts = answer.split('<hr>') 
    const activity = {
      attachments: texts.map(text => ({ content: { text } }))
    }
    Dispatcher.dispatch({
      type: keys.addBotMessage,
      content: {...activity, from:'bot', now: new Date()}
    })
  },
  addBotFeedbackMessage(buttons, text){
    const activity = {
      attachments:[ { content: { buttons, text} } ]
    }
    Dispatcher.dispatch({
      type: keys.addBotMessage,
      content: {...activity, from:'bot', now: new Date()}
    })
  },
  addOperatorMessage(text){
    Dispatcher.dispatch({
      type: keys.addOperatorMessage,
      content: {from:'operator', now: new Date(), text}
    })
  },
}
import React from "react"

export default ({onClick , disabled, isDisplay}) => {
  const toggle = (event) => {
    if(!disabled)onClick(event)
  }
  return (isDisplay)?(
  <div className="hamburgerMenu__btn" onClick={toggle} disabled={disabled}>
  <i></i>
  <i></i>
  <i></i>
</div>
 ):(<div></div>)}

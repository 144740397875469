import Dispatcher from "../Dispatcher"

export const keys = {
  scroll: Symbol("scroll:from-event-listener"),
}

export default {
  scroll(){
    const scroll = window.scrollY || window.pageYOffset;
    const streamLength = document.getElementById('message_stream').clientHeight
    const displayedLength = window.innerHeight
    const isDisplay = (scroll < (streamLength - displayedLength) * 2 / 3) ? 'is-display' : '';
    Dispatcher.dispatch({
      type: keys.scroll,
      isDisplay,
    })
  },
}
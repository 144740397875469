import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/StatusActions"
import { settings } from "../config/settings"

class StatusStore extends ReduceStore {

  getInitialState() {
    return settings.status
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.isProcessing:
      case keys.isCompleted:
      case keys.isError:
      case keys.isAlert:
      case keys.initialize:
        return action

      default:
        return state
    }
  }
}

export default new StatusStore(Dispatcher)

import Dispatcher from "../Dispatcher"

export const keys = {
  enable: Symbol("drop-area:enable"),
  disable: Symbol("drop-area:disable"),
  show: Symbol("drop-area:show"),
  hide: Symbol("drop-area:hide"),
}

export default {
  enable(){
    Dispatcher.dispatch({
      type: keys.enable,
    })
  },
  disable(){
    Dispatcher.dispatch({
      type: keys.disable,
    })
  },
  show(){
    Dispatcher.dispatch({
      type: keys.show,
    })
  },
  hide(){
    Dispatcher.dispatch({
      type: keys.hide,
    })
  },
}
import React from "react"
import 'react-toastify/dist/ReactToastify.min.css';
import i18n from "../i18n"
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({ title="", msg="" }) => {
  return (
  <div>
    <h2 className={"announcement-title"}>{i18n.t(title)}</h2>
    <div className={"announcement-link"}>
      <CustomReactMarkdown children={msg} linkTarget={()=>"_blank"}/>
    </div>
  </div>
)}

